/*
* Fullwidth Layout
*/

.rd-navbar-fullwidth {
  display: block;

  // RD Navbar Panel
  .rd-navbar-panel{
  }

  // RD Navbar nav wrap
  .rd-navbar-nav-wrap{

  }

  // RD Navbar Nav
  .rd-navbar-nav {
    width: 100%;

    > li + li {
      margin-left: $navbar-fullwidth-nav-indent;
    }
  }
}
//
// Search Results
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.search_list {
  text-align: left;
  padding-left: 20px;
  font-size: 18px;
  list-style-type: none;
  overflow: hidden;

  li div {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
  }

  li:before {
    top: 6px;
    @include transform (translateY(0));
  }

  li + li {
    margin-top: 25px;
  }

  h5 + * {
    margin-top: 8px;
  }

  p {
    margin-bottom: 8px;
  }

  li:only-child::before {
    display: none;
  }
}

.search_link {
  &:hover {
    color: $gray;
  }
}

.result-item {
  padding-left: 20px;
  list-style-type: decimal;
  margin-left: 20px;
  color: $gray-dark;
  > * {
    color: $gray-darker;
  }
  p {
    font-size: $font-size-base;
  }

  + .result-item {
    margin-top: 40px;
  }
  @include media-breakpoint-up(md) {
    padding-left: 30px;
  }
}

.search_title {
  color: $black;
}

.match {
  font-size: $font-size-sm;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  color: $primary;
  em {
    font-style: normal;
  }
}

.search {
  color: $white;
  background: $primary;
}

#rd-search-results-live{
  #search-results {
    background: $white;
    box-shadow: $shadow-area-xs;
    border-top: 1px solid $gray;
    padding: 20px;
    opacity: 0;
    visibility: hidden;
    transition: 0.33s all ease-in;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    &.active {
      visibility: visible;
      opacity: 1;
    }
    .result-item {
      padding-left: 15px;
    }

    p.match {
      font-size: 14px;
      letter-spacing: 0;

      em {
        display: block;
      }
    }
    .result-item {
      + .result-item {
        margin-top: 20px;
      }
    }
    .search_all{
      margin-top: 20px;
      text-align: center;

      a{
        transition: .3s all ease;
        &:hover{
          color: $gray;
        }
      }
    }
  }
}


// RD Search Sidebar
// --------------------------------------------------

.rd-search-sidebar {
  position: relative;

  .form-input {
    height: 51px;
    padding-left: 22px;
    padding-right: 42px;
  }

  .form-label {
    top: 25px;
    left: 24px;
  }

  .form-search-submit {
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    background: transparent;
    border: none;
    cursor: pointer;

    &, .icon {
      transition: .3s all ease;
    }

    &:hover {
      .icon {
        color: $primary;
      }
    }

    .icon-xs {
      font-size: 20px;
    }
  }
}

.tablet, .mobile {
  .rd-search-sidebar {
    .form-search-submit {
      right: 0;
    }
  }
}

* + .search-list { margin-top: 30px; }

.search-list {
  h5 + * { margin-top: 10px; }
  p + p { margin-top: 10px; }

  li + li {
    margin-top: 25px;
  }

  a:hover {
    color: $gray;
  }

}
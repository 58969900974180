/*
* Fixed Layout
*/
.rd-navbar-fixed {
  display: block;

  // RD Navbar Brand
  .rd-navbar-brand {
    display: block;
    text-align: left;
    position: fixed;
    top: 3px;
    left: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 22px;
    line-height: 46px;
    height: 48px;
    z-index: 17;
    transform: translateX(-50%);

    .brand-name {
      color: $black;
      display: inline-block;
    }
  }

  // RD Navbar Panel
  .rd-navbar-panel {
    display: flex;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    padding: ($rd-navbar-fixed-height - $rd-navbar-fixed-line-height) / 2;
    height: $rd-navbar-fixed-height;
    color: $rd-navbar-fixed-panel-color;
    z-index: 999;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      box-shadow: $rd-navbar-fixed-shadow;
      background: $rd-navbar-fixed-panel-background;
    }
  }

  // RD Navbar Toggle
  .rd-navbar-toggle {
    display: inline-block;
    position: fixed;
    top: 4px;
    left: 3px;
    padding: 0;
  }

  .rd-navbar-nav-wrap {
    @extend %rd-navbar-transition;
    position: fixed;
    top: -56px;
    left: 0;
    width: 232px;
    padding: 112px 0 56px;
    bottom: -56px;
    color: $white;
    background: $rd-navbar-background;
    z-index: 15;
    transform: translateX(-120%);

    &.active {
      transform: translateX(0);
    }
  }

  .rd-navbar-nav {
    display: block;
    height: 100%;
    overflow: auto;
    font-size: 16px;
    line-height: 26px;
    text-align: left;

    &:before, &:after {
      content: '';
      display: block;
      height: 8px;
    }

    li {
      > a {
        display: block;
        padding: 11px 56px 11px 16px;
      }

      &.opened > a, a:hover {
        background: lighten($white, 75%);
      }

      &.active {
        .rd-navbar-submenu-toggle {
          &:after {
            color: $white;
          }
        }

        > a {
          color: $white;
          background: $primary;
        }
      }
    }
  }

  .rd-navbar-dropdown,
  .rd-navbar-megamenu {
    display: none;
  }

  .rd-navbar-submenu {
    position: relative;
    @extend %rd-navbar-transition;

     .rd-navbar-dropdown > li > a {
      padding-left: 30px;
    }

    .rd-navbar-dropdown li li > a,
    .rd-navbar-megamenu ul li li > a {
      padding-left: 48px;
    }

    &.opened {
      > .rd-navbar-dropdown,
      > .rd-navbar-megamenu {
        display: block;
      }
    }
  }

  // RD Navbar Nav
  .rd-navbar-nav {
    li {
      &:hover,
      &.focus {
        > a, > a:hover {
          color: $rd-navbar-nav-hover-color;
          background: $rd-navbar-nav-active-background;
        }
        > .rd-navbar-submenu-toggle {
          &:after {
            color: #fff;
          }
          &:hover {
            cursor: pointer;
            color: #fff;
          }
        }
      }

      .rd-navbar-dropdown,
      .rd-navbar-megamenu {
        transition: opacity 0.3s, height 0.4s ease;
        opacity: 0;
        height: 0;
        overflow: hidden;
      }

      &.opened {
        > .rd-navbar-dropdown,
        > .rd-navbar-megamenu {
          opacity: 1;
          height: auto;
        }
        > a {
          background: $rd-navbar-nav-active-background;
          color: $rd-navbar-nav-hover-color;
        }
        > .rd-navbar-submenu-toggle {
          &:after {
            color: #fff;
          }
          &::after {
            @include transform (rotate(180deg));
          }
        }
      }

    }

    .rd-navbar-submenu-toggle {
      &::after {
        content: '\f078';
        position: absolute;
        top: 0;
        right: 0;
        margin-top: -5px;
        width: 65px;
        height: 48px;
        font: 400 12px/12px "FontAwesome";
        line-height: 48px;
        text-align: center;
        transition: 0.4s all ease;
        z-index: 2;
        color: $rd-navbar-fixed-panel-color;
      }
    }
  }

  // Hybrid Styles
  .rd-navbar-collapse,
  .rd-navbar-search-toggle {
    position: fixed;
    top: ($rd-navbar-fixed-height - $rd-navbar-fixed-line-height) / 2;
    height: $rd-navbar-fixed-line-height;
    z-index: 1000;

    background-color: transparent;
    border: none;
    &:focus {
      outline: none;
    }

  }

  // RD Navbar States
  &.active {
    .rd-navbar-nav {
      transform: translateX(0);
    }
  }

  &.rd-navbar--on-search {
    @media (max-width: 1199px) {
      .brand-name {
        opacity: 0;
        visibility: hidden;
        transform: scale(0.7);
      }
    }
  }

  &.rd-navbar--is-clone {
    display: none;

    .rd-navbar-panel {
      transform: translateY(-110%);
    }

    &.rd-navbar--is-stuck {
      .rd-navbar-panel {
        transform: translateY(0);
      }
    }
  }

  .rd-navbar-fixed--visible {
    display: block;
  }

  .rd-navbar-fixed--hidden {
    display: none;
  }
}

html.rd-navbar-fixed-linked {
  body {
    padding-top: 55px;
  }
}
.team{
    height: 430px;
    .title{
        font-size: 22px;
        font-weight: bold;
    }
    .subtitle{
        font-size: 16px;
    }
}
// .d-team{
//     height: 400px;
// }

@media (max-width: 768px) {
    .team{
        height: auto !important;
    }
  }

  @media (max-width: 500px) {
    .team{
        height: auto !important;
    }
  }
// Contexts
// -------------------------

%context-dark {
  > *, a,
  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    color: $white;
  }

  .time_circles > div >  {
    h4, span {
      color: $white;
    }
  }
}

%context-light {
  > *,
  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    color: $black;
  }
}


.context-dark {
  @extend %context-dark;
}

.context-light {
  @extend %context-light;
}
//Tick width & range thumb width need to be in sync, that's why I'm using the $unit variable.
$unit: 2px;


//Some basic setup first
// * {box-sizing: border-box;}

// body {
//   font-family: sans-serif;
//   font-size: $unit * 2;
//   line-height: $unit * 2;
//   color: black;
//   background: white;
// }


//Some range styling.
//Using mixins because each major browser needs a separate selector to style parts of the range input, combining selectors would invalidate the whole selector on each browser
@mixin range-track {
  color: transparent;
}
@mixin range-thumb {
//   box-shadow: 0px 2px 10px -2px black(1);
}


.customSlider{
    .sliderLabel{
        font-size: 16px;
        font-weight: 600;
    }

    .sliderOutput{
        background-color: #ffffff;

        &:focus{
            box-shadow: none;
        }
    }
}

input[type=range] {

  &:focus{
      box-shadow: none;
  }
}

input[type=range]::-webkit-slider-runnable-track {
  @include range-track();
}
input[type=range]::-moz-range-track {
  @include range-track();
}
input[type=range]::-ms-track {
  @include range-track();
}
input[type=range]::-ms-fill-lower {
  display: none;
}
input[type=range]::-ms-fill-upper {
  display: none;
}
input[type=range]::-webkit-slider-thumb {
  @include range-thumb();
}
input[type=range]::-moz-range-thumb {
  @include range-thumb();
}
input[type=range]::-ms-thumb {
  @include range-thumb();
}

//And now the ticks
.ticks {
  display: flex;
  justify-content: space-between;
  
  //We need left & right padding that's half the width of the range thumb, so all ticks align with the center of the thumb
  padding: $unit $unit*2;
}

.tick {
  position: relative;
  margin-top: -10px !important;
  display: flex;
  justify-content: center;
  width: 1px;
  margin-bottom: $unit*2;
  
}

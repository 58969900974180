/* 
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
	-webkit-animation-duration: 1000ms;
	animation-duration: 1000ms;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
	z-index: 0;
}

.owl-carousel .owl-animated-out {
	z-index: 1;
}

.owl-carousel .fadeOut {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
	-webkit-transition: height 500ms ease-in-out;
	-moz-transition: height 500ms ease-in-out;
	-ms-transition: height 500ms ease-in-out;
	-o-transition: height 500ms ease-in-out;
	transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
}

.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
	display: flex;
	justify-content: flex-start;
}

.owl-carousel.owl-carousel-centered .owl-stage {
	align-items: center;
}

.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	/* fix for flashing background */
	-webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
	cursor: pointer;
	cursor: hand;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.owl-carousel.owl-loaded {
	display: block;
}

.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}

.owl-carousel.owl-hidden {
	opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
	display: none;
}

.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	display: inline-block;
	-webkit-backface-visibility: hidden;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.owl-carousel.owl-text-select-on .owl-item {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.owl-carousel .owl-grab {
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab;
}

.owl-carousel.owl-rtl {
	direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
	float: right;
}

/* No Js */
.no-js .owl-carousel {
	display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	-webkit-transition: opacity 400ms ease;
	-moz-transition: opacity 400ms ease;
	-ms-transition: opacity 400ms ease;
	-o-transition: opacity 400ms ease;
	transition: opacity 400ms ease;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000;
}

.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	font: 400 40px/80px 'FontAwesome';
	cursor: pointer;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	-webkit-transition: scale 100ms ease;
	-moz-transition: scale 100ms ease;
	-ms-transition: scale 100ms ease;
	-o-transition: scale 100ms ease;
	transition: scale 100ms ease;
	&:before {
		content: '\f144';
	}
}

.owl-carousel .owl-video-play-icon:hover {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
	display: none;
}

.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	-webkit-background-size: contain;
	-moz-background-size: contain;
	-o-background-size: contain;
	background-size: contain;
	-webkit-transition: opacity 400ms ease;
	-moz-transition: opacity 400ms ease;
	-ms-transition: opacity 400ms ease;
	-o-transition: opacity 400ms ease;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
}

// Owl Insets
.owl-carousel-inset-bottom-20 .owl-stage {
	padding-bottom: 20px;
}

/*
 * Owl Navigation
 */

.owl-nav {
	display: none;

	@include media-breakpoint-up(xl) {
		display: block;
	}
}

.owl-prev, .owl-next {
	position: absolute;
	top: 50%;
	margin-top: -15px !important;
	font: 400 44px/44px 'FontAwesome';
	transition: .3s all ease;
	transform: translateY(-50%);

	&:hover {
		opacity: .5;
	}

	@media (min-width: 1200px) and (max-width: 1399px) {
		width: 55px;
		height: 55px;
		line-height: 50px;
		border-radius: 50%;
		background: $white;
	}
}

.owl-prev {
	left: 15px;

	&:before {
		content: '\f104';
	}

	@include media-breakpoint-up(xxl) {
		left: -48px;
	}
}

.owl-next {
	right: 15px;

	&:before {
		content: '\f105';
	}

	@include media-breakpoint-up(xxl) {
		right: -48px;
	}
}

.owl-carousel-minimal {
	.owl-prev, .owl-next {
		margin-top: 21px !important;
		background: transparent;
	}

	.owl-next {
		right: 243px;
	}
	.owl-prev {
		left: 243px;
	}
}

@media (min-width: 1200px) and (max-width: 1399px) {
	.owl-nav-modern {
		.owl-prev:before {
			margin-left: -5px;
		}
		.owl-next:before {
			margin-right: -5px;
		}
	}
}

.owl-carousel-navs-primary {
	.owl-prev, .owl-next {
		color: $gray;

		&:hover {
			opacity: 1;
			color: $primary;
		}
	}
}

/*
 * Owl Pagination
 */

.owl-dots {
	text-align: center;
	margin-top: 25px;

	@include media-breakpoint-up(xl) {
		display: none !important;
	}
}

.owl-dot {
	display: inline-block;
	text-align: center;
	border-radius: 50%;
	position: relative;
	width: 13px;
	height: 13px;
	outline: none;
	cursor: pointer;
	border: 1px solid $primary;
	background: $primary;
	transition: .3s all ease;

	& + .owl-dot {
		margin-left: 9px;
	}

	&:hover,
	&:focus {
		background: transparent;
	}

	&.active {
		background: transparent;
	}
}

.owl-nav, .owl-dots {
	&.disabled {
		display: none;
	}
}
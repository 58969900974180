//
// Custom typography
// --------------------------------------------------

// Page
// -------------------------
.page {
	min-height: 100vh;
	position: relative;
}


// Body
body {
	font-family: $font-family-base;
	font-size: $font-size-base;
	line-height: $line-height-base;
	font-weight: $font-weight-base;
	color: $text-color;
	background-color: $body-bg;
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: subpixel-antialiased;
}


img {
	max-width: 100%;
	height: auto;
	display: inline-block;
}



.page {
	overflow: hidden;
}


// Page header
// -------------------------

.page-header {
	padding: 0;
	margin: 0;
	border-bottom: none;
}

// Fonts
// -------------------------

.font-default {
	font-family: $font-family-base;
}

.font-accent {
	font-family: $font-family-sec;
}

// Basic Typography
// -------------------------

#{headings()} {
	margin-top: 0;
	margin-bottom: 0;
	font-family: $headings-font-family;
	font-weight: $headings-font-weight;
	line-height: $headings-line-height;
	color: $headings-color;

	small,
	.small {
		font-weight: normal;
		line-height: 1;
		color: $headings-small-color;
	}
}

h1,
.h1 {
	font-size: 44px;
	line-height: $h1-line-height;

	@include media-breakpoint-up(md) {
		font-size: $h1-font-size;
	}
}

h2,
.h2 {
	font-size: 34px;
	line-height: $h2-line-height;

	@include media-breakpoint-up(md) {
		font-size: $h2-font-size;
	}
}

h3,
.h3 {
	font-size: 28px;
	line-height: $h3-line-height;

	@include media-breakpoint-up(md) {
		font-size: $h3-font-size;
	}
}

h4,
.h4 {
	font-size: 36px;
	line-height: $h4-line-height;

	@include media-breakpoint-up(md) {
		font-size: $h4-font-size;
	}
}

h5,
.h5 {
	font-size: 20px;
	line-height: $h5-line-height;

	@include media-breakpoint-up(md) {
		font-size: $h5-font-size;
	}
}

h6,
.h6 {
	font-size: $h6-font-size;
	line-height: $h6-line-height;
	font-weight: 700;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	a {
		transition: .3s all ease;
	}

	a:hover {
		color: $primary;
	}

	.small {
		color: $gray-dark;
	}
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
	font-family: $font-family-base;
}

p,
.p,
.list {
	a {
		transition: .3s all ease;
	}

	a:hover {
		text-decoration: underline;
	}
}

p {
	margin: 0 0 ($line-height-computed / 2);
}

@include media-breakpoint-up(sm) {
	.big {
		font-size: $font-size-lg;
		line-height: floor(($font-size-lg * $line-height-large));

		h1 &,
		.h1 & {
			font-size: 142.22%;
			line-height: 1.125;
		}

		h2 &,
		.h2 & {
			font-size: 147.36%;
			line-height: 1.142857142857143;
		}
	}
}

small,
.small {
	font-size: $font-size-sm;
	line-height: floor(($font-size-sm * $line-height-small));
}

.text-big {
	font-size: 18px;
	line-height: 28px;
}

.text-big-80 {
	font-size: 54px;
	line-height: 54px;

	@include media-breakpoint-up(md) {
		font-size: 68px;
		line-height: 68px;
	}

	@include media-breakpoint-up(lg) {
		font-size: 80px;
	}

	@include media-breakpoint-up(xl) {
		line-height: 1.2;
	}
}

code {
	padding: 2px;
	font-size: inherit;
	font-family: inherit;
	letter-spacing: .02em;
	border-radius: 0;
	color: $white;
	background-color: $primary;
}

mark,
.mark {
	background-color: $primary;
	padding: .1em;
}

// Lists
// -------------------------

ul,
ol {
	list-style: none;
	padding: 0;
	margin: 0;
}

.list {
	>li+li {
		margin-top: 11px;
	}
}

.list-white {

	li,
	a {

		&,
		&:hover,
		&:focus {
			color: $white;
		}
	}
}

// List inline
//

.list-inline {
	margin-left: -5px;
	margin-right: -5px;

	>li {
		padding-left: 5px;
		padding-right: 5px;
		display: inline-block;
	}
}

.list-inline-2 {
	margin-left: -2px;
	margin-right: -2px;

	>li {
		padding-left: 2px;
		padding-right: 2px;
	}
}

.list-inline-12 {
	margin-left: -12px;
	margin-right: -12px;

	>li {
		padding-left: 12px;
		padding-right: 12px;
	}
}

.list-inline-22 {
	margin-left: -22px;
	margin-right: -22px;

	>li {
		padding-left: 22px;
		padding-right: 22px;
	}
}

@include media-breakpoint-up(md) {
	.list-inline-sm-center {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.list-inline-top {
	>li {
		vertical-align: top;
	}
}

.list-inline-dashed {
	>li {
		padding-right: 0;

		&:after {
			content: '/';
			padding-left: 8px;
		}

		&:last-child {
			&:after {
				display: none;
			}
		}
	}

	&-vertical {
		&>li {
			position: relative;
			vertical-align: middle;

			&:after {
				content: '';
				display: none;
				position: absolute;
				top: 50%;
				right: -12px;
				width: 1px;
				height: 27px;
				background: $gray-lighter;
				transform: translateY(-50%);
			}
		}

		@include media-breakpoint-up(md) {
			>li {
				padding-right: 0;

				&:after {
					display: block;
				}


				&:last-child {
					padding-right: 22px;

					&:after {
						display: none;
					}
				}
			}
		}

		@include media-breakpoint-up(lg) {
			&>li {
				&:after {
					display: none;
				}
			}
		}

		@include media-breakpoint-up(xl) {
			&>li {
				&:after {
					display: block;
				}
			}
		}


	}
}

.list-inline-0 {
	margin-left: 0;
	margin-right: 0;

	>li {
		padding-left: 0;
		padding-right: 0;
	}
}

// Description Lists
dl {
	margin: 0;
}

// List terms
//

.list-terms {
	margin-top: 35px;

	dt+dd {
		margin-top: 10px;
	}

	dd+dt {
		margin-top: 35px;
	}

	@include media-breakpoint-up(xl) {
		dt+dd {
			margin-top: 20px;
		}

		dd+dt {
			margin-top: 30px;
		}
	}
}

// List index
//

.list-index {
	counter-reset: li;

	li {
		position: relative;
		padding-left: 24px;

		.list-index-counter,
		&.list-index-counter {
			&:before {
				content: counter(li, decimal-leading-zero) '.';
				counter-increment: li;
				position: absolute;
				top: 0;
				left: 0;
				color: $gray-light;
			}
		}
	}
}

// List marked
//

.list-marked {
	li {
		position: relative;
		padding-left: 16px;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 8px;
			display: inline-block;
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background: $primary;
		}
	}
}

// Shadows
// -------------------------

.shadow-drop-ambient {
	box-shadow: $shadow-area-ambient;
}

.shadow-drop-xxs {
	box-shadow: $shadow-area-xxs;
}

.shadow-drop-xs {
	box-shadow: $shadow-area-xs;
}

.shadow-drop-sm {
	box-shadow: $shadow-area-sm;
}

.shadow-drop-md {
	box-shadow: $shadow-area-md;
}

.shadow-drop-lg {
	box-shadow: $shadow-area-lg;
}

.shadow-drop-xl {
	box-shadow: $shadow-area-xl;
}

// Misc
// -------------------------

// Blockquotes
blockquote {
	q {

		&:before,
		&:after {
			content: none;
		}
	}

	cite {
		font-style: normal;
	}
}

// Addresses
address {
	margin-top: 0;
	margin-bottom: 0;
}

// Backgrounds
// -------------------------

// Contextual backgrounds
//

.bg-black {
	@include bg-variant-custom(#{$black});
}

.bg-gray-darker {
	@include bg-variant-custom(#{$gray-darker});
}

.bg-gray-dark {
	@include bg-variant-custom(#{$gray-dark});
}

.bg-gray {
	@include bg-variant-custom(#{$gray});
}

.bg-gray-light {
	@include bg-variant-custom(#{$gray-light});
}

.bg-gray-lighter {
	@include bg-variant-custom(#{$gray-lighter});
}

.bg-default {
	@include bg-variant-custom(#{$white});
}

.bg-accent {
	@include bg-variant-custom(#{$primary});
}

// Background Overlays
//

.bg-overlay-black {
	background: rgba($black, .2);
	height: 170px !important;
}

.bg-overlay-black1 {
	background: rgba($black, .2);
	height: 220px !important;
}

.bg-overlay-gray-dark {
	background: rgba($black, .35);
}

.bg-overlay-gray-darken {
	background: rgba($black, .5);
}

.bg-overlay-green-leaf {
	background: rgba($brand-green-leaf, .3);
}

// Background Images
// -------------------------

.bg-image {
	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	&-1:before {
		background-image: url(./../../assets/images/offers/box-offer-04-960x459.jpg);
	}

	&-2:before {
		background-image: url(./../../assets/images/offers/box-offer-05-960x459.jpg);
	}

	&-3:before {
		background-image: url(./../../assets/images/offers/box-offer-01-640x459.jpg);
	}

	&-4:before {
		background-image: url(./../../assets/images/offers/box-offer-02-640x459.jpg);
	}

	&-5:before {
		background-image: url(./../../assets/images/offers/box-offer-03-640x459.jpg);
	}
}

.bg-image-hover {
	overflow: hidden;

	&:before {
		transition: .8s all ease;
	}

	&:hover {
		&:before {
			transform: scale(1.08);
		}
	}
}

.tablet,
.mobile {
	.bg-image-hover {
		&:hover {
			&:before {
				transform: scale(1);
			}
		}
	}
}

.bg-image-overlay {
	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba($black, .2);
	}
}

// Colors
// -------------------------

// Contextual colors
//

.page {
	@include text-emphasis-variant('.text-black', $black);
	@include text-emphasis-variant('.text-black-06', rgba($black, .6));
	@include text-emphasis-variant('.text-gray-darker', $gray-darker);
	@include text-emphasis-variant('.text-gray-dark', $gray-dark);
	@include text-emphasis-variant('.text-gray', $gray);
	@include text-emphasis-variant('.text-gray-light', $gray-light);
	@include text-emphasis-variant('.text-gray-lighter', $gray-lighter);
	@include text-emphasis-variant('.text-white', $white);
	@include text-emphasis-variant('.text-primary', $primary);
	@include text-emphasis-variant('.text-warning', $brand-warning);
	@include text-emphasis-variant('.text-gallery', $brand-gallery);
	@include text-emphasis-variant('.text-silver-chalice', $brand-silver-chalice);
}

html .page {
	.link-decoration-none {
		&:hover {
			text-decoration: none;
		}
	}

	.text-hover-primary {
		transition: .3s all ease;

		&:hover {
			color: $primary;
		}
	}
}

// Contact Info
// -------------------------

.contact-info {

	dt,
	dd {
		display: inline-block;
	}

	dt {
		font-weight: 400;
	}
}

/*
 Caret
 */

.caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 2px;
	vertical-align: middle;
	border-top: 4px dashed;
	border-top: 4px solid \9;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
}

/*
 Grid Custom
 */

.row-10 {
	margin-bottom: -10px;

	&>* {
		margin-bottom: 10px;
	}
}

.row-12 {
	margin-bottom: -12px;

	&>* {
		margin-bottom: 12px;
	}
}

.row-20 {
	margin-bottom: -20px;

	&>* {
		margin-bottom: 20px;
	}
}

.row-30 {
	margin-bottom: -30px;

	&>* {
		margin-bottom: 30px;
	}
}

/*
 Grid Custom
 */


.row-40 {
	margin-bottom: -20px;

	&>* {
		margin-bottom: 20px;
	}

	@include media-breakpoint-up(md) {
		margin-bottom: -40px;

		&>* {
			margin-bottom: 40px;
		}
	}
}

.row-50 {
	margin-bottom: -30px;

	&>* {
		margin-bottom: 30px;
	}

	@include media-breakpoint-up(md) {
		margin-bottom: -50px;

		&>* {
			margin-bottom: 50px;
		}
	}
}

.row-65 {
	margin-bottom: -30px;

	&>* {
		margin-bottom: 30px;
	}

	@include media-breakpoint-up(md) {
		margin-bottom: -65px;

		&>* {
			margin-bottom: 65px;
		}
	}

}

.row-70 {
	margin-bottom: -50px;

	&>* {
		margin-bottom: 50px;
	}

	@include media-breakpoint-up(md) {
		margin-bottom: -70px;

		&>* {
			margin-bottom: 70px;
		}
	}

}

.row-no-gutter {
	margin-left: 0;
	margin-right: 0;

	&>[class*="col-"] {
		padding-left: 0;
		padding-right: 0;
	}
}

.row-narrow-12 {
	margin-right: -6px;
	margin-left: -6px;

	&>[class*="col-"] {
		padding-right: 6px;
		padding-left: 6px;
	}
}

/*
 Frame header
 */

.frame-header {
	width: 100%;
	outline: none;
	border: 0;
	height: 500px;
	max-height: 500px;
}

.frame-header-big {
	height: 900px;
}

.rd-navbar-nav-flex {
	display: flex;
	align-items: center;
	justify-content: center;
}

/*
 Row Offset Custom
 */

// Large ≥992px
@include media-breakpoint-up(lg) {
	.row-custom-offset {
		padding-bottom: 30px;
	}
}

.rights {
	margin-bottom: 0;

	&.text-white {
		a {
			color: $white;
		}
	}
}

/*
 Builder Styles Here
 */
[data-x-mode="true"] {
	.rd-navbar-static {
		.rd-navbar-submenu-toggle {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: -20px;

			&:after {
				content: '\f0d7';
				font-family: FontAwesome;
			}
		}
	}

	.bounding-fix {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			top: -20px;
			left: 0;
			width: 100%;
			height: 40px;
		}
	}
}

.custom-title {
	white-space: nowrap;
}

// @media (max-width: 767px) {
// 	.custom-title {
// 		display: block;
// 	}

// 	.custom-title::after {
// 		content: 'Caffeine Content Of';
// 		display: block;
// 	}

// 	.custom-title::before {
// 		content: 'Foods And Beverages' !important;
// 		display: block !important;
// 	}
// }

@media (max-width: 576px) {

	.page-header.title .title-part1,
	.page-header.title .title-part2 {
		display: block;
	}
}
$anim-speed: 0.3s;

.tiles {
	.tile {
		display: inline-block;
		margin: 10px;
		text-align: left;
		opacity: .99;
		overflow: hidden;
		position: relative;
		border-radius: 3px;
		box-shadow: 0 0 20px 0 rgba(0, 0, 0, .05);

		&:before {
			content: '';
			background: linear-gradient(to bottom,
					rgba(0, 0, 0, 0) 0%,
					rgba(0, 0, 0, 0.7) 100%);
			width: 100%;
			height: 50%;
			opacity: 0;
			position: absolute;
			top: 100%;
			left: 0;
			z-index: 2;
			transition-property: top, opacity;
			transition-duration: $anim-speed;
		}

		.details {
			font-size: 16px;
			padding: 20px;
			color: #fff;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 3;

			span {
				display: block;
				opacity: 1;
				position: relative;
				top: 50px;
				transition-property: top, opacity;
				transition-duration: $anim-speed;
				transition-delay: 0s;
			}

			.title {
				font-weight: 600;
				font-size: 35px;
				line-height: 1.4;
			}

			.info {
				line-height: 1.2;
				margin-top: 5px;
				font-size: 18px;
			}
		}

		&:focus,
		&:hover {

			&:before,
			span {
				opacity: 1;
			}

			&:before {
				top: 50%;
			}

			span {
				top: 0;
			}

			.title {
				transition-delay: 0.15s;
			}

			.info {
				transition-delay: 0.25s;
			}
		}
	}
}

//Ends: Caluculator page
.card-bs {
	box-shadow: 0 10px 34px -15px rgb(0 0 0 / 24%);
}

// Ends: Login page

.contact-bg {
	background: url("../../public/images/home/resized/contact.jpg"), linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5));
	;
	// opacity: 0.2;
	background-repeat: no-repeat;
	background-size: cover;
	background-blend-mode: overlay;
}

.login-bg {
	background: url("../assets/images/banner/banner1.jpg"), linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5));
	background-repeat: no-repeat;
	background-size: cover;
	background-blend-mode: overlay;
}

// Contact us

.rounded-check {
	background: #a6c550;
	padding: 2px;
	border-radius: 50%;

	&:before {
		color: #fff;
	}
}

.rd-navbar-default {

	// RD Navbar Default Fullwidth and Static
	// --------------------------------------------------
	&.rd-navbar-fullwidth,
	&.rd-navbar-static {
		.rd-navbar-nav {
			border-top: none;
		}
	}
}

.search-bg {
	background-image: url("../../public/images/home/resized/search.jpg"), linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5));
	;
	background-blend-mode: overlay;

	.search-section {
		height: 400px;
	}
}

.bg-cover-contact {
	background-image: url("../../public/images/home/resized/about-news.jpg"), linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5));
	;
	background-blend-mode: oerlay;

	.search-section {
		height: 500px;
	}
}




.search-about-bg7 {
	background-image: url("../assets/images/banner/banner7.jpg"), linear-gradient(rgba(23, 23, 23, 0.913), rgba(31, 31, 31, 0.785));
	;
	background-blend-mode: overlay;

	.search-section {
		height: 80vh;

	}
}


.search-about-bg2 {
	background-image: url("../assets/images/banner/iStock.jpg"), linear-gradient(rgba(23, 23, 23, 0), rgba(31, 31, 31, 0)) !important;
	background-blend-mode: overlay;

	.search-section {
		height: 80vh;

	}
}

.search-bgfeb {
	background-image: url("../../public/images/home/resized/fev.jpg");
	background-blend-mode: overlay;

	.search-section {
		height: 78vh;

	}
}

.search-bg2 {
	background-image: url("../../public/images/home/resized/about-news.jpg");
	background-blend-mode: overlay;

	.search-section {
		height: 80vh;

	}
}


.alcohol-bg {
	background-image: url("../../src/assets/alcohol_drug.jpg");
	background-blend-mode: overlay;

	.search-section {
		height: 80vh;

	}
}

.grayfruit-bg {
	background-image: url("../../src/assets/grapefruit_img.jpg");
	background-blend-mode: overlay;

	.search-section {
		height: 80vh;

	}
}

.mdsHighRisk-bg {
	background-image: url("../../src/assets/images/banner/mdsHighRiskBanner.jpg");
	background-blend-mode: overlay;

	.search-section {
		height: 80vh;

	}
}



.search-bg1 {
	background-image: url("../assets/baner.jpg");
	background-blend-mode: overlay;

	.search-section {
		height: 80vh;
	}
}

.pageHeader-imgs {
	background-image: url("../assets/baner.jpg"), linear-gradient(rgba(23, 23, 23, 0.162), rgba(31, 31, 31, 0.151));
	;
	background-blend-mode: overlay;

	.search-section {
		height: 80vh;

	}
}

.search {
	position: relative;

	input {
		height: 60px;
		text-indent: 25px;
		border: 2px solid #d6d4d4;

		&:focus {
			box-shadow: none;
			border: 2px solid #15678f;
		}
	}

	.fa-search {
		position: absolute;
		top: 26px;
		left: 16px
	}

	button {
		position: absolute;
		top: 0px;
		right: 0;
		height: 60px;
		width: 110px;
		background: #15678f;
		border-radius: 0;
		border-color: #15678f !important;
	}
}

// Search page css ends
.drug-accordian {
	// .accordion-button,.card-body{
	// 	font-size: 20px;
	// 	font-weight: normal;
	// 	text-decoration: none;
	// }
}

.accordion {
	.accordion-button {
		&:before {
			content: '\f078';
			font-family: FontAwesome;
			float: right;
			transition: all 0.5s;
		}

		&.collapsed {
			.accordion-button {
				&:before {
					content: '\f077';
				}
			}
		}
	}
}

// Drug details page
.drug-list-tab {
	&.nav-tabs {
		.nav-link {
			margin-bottom: -1px;
			border: 1px solid #f7f7f7;
			border-top-left-radius: 0.25rem;
			border-top-right-radius: 0.25rem;
			font-size: 20px;

			&.active {
				color: #ffffff;
				background: #a6c550;
			}
		}
	}
}

// .drug-details-list .icon-img {
//     width: 40px;
//     height: 39px !important;
// }
.drug-details-list {
	.icon-img {
		width: 40px !important;
		height: 39px !important;
	}

	.link {
		color: #15678f;
	}

	.drug-info,
	.drug-info span {
		.arrow-up {
			width: 0;
			height: 0;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			// border-bottom: 14px solid #000;
		}

		.arrow-down {
			width: 0;
			height: 0;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 14px solid #000
		}
	}
}

// .border-bottom {
//     border-bottom: 1px solid #dee2e6 !important;
// }

// Drug List
.account {
	.form-check-inline .form-check-input {
		position: static;
		margin-top: -5px;
		margin-right: 0.3125rem;
		/* margin-left: 0; */
	}

	// p
	// .fa, 
	// .sidebar-text,
	// .topic-check{
	// 	font-size: 18px;
	// 	line-height: 1.6;
	// }
	// .form-check{
	// 	.form-check-input{
	// 		width: 20px;
	// 		height: 20px;
	// 		right: 10px;
	// 		top: 3px;
	// 		margin-right: 10px;
	// 	}
	// }
	// .card-header{
	// 	.form-check{
	// 		.form-check-label{
	// 			font-size: 16px;
	// 		}
	// 	}
	// }
}

// account

.sidebar-content {
	.fa {
		font-size: 16px;
	}
}

// Sidebar
.bg-overlay-pink {
	background: rgb(137 82 95 / 64%);
}

.swiper-wrapper {
	height: 400px !important;
}

.swiper-container {
	height: 400px !important;
}

.bg-overlay-pink {
	margin-top: 0px !important;
}

.parallax-content {
	margin-top: -20px !important;
}

// .eWjCzc {
//     box-sizing: border-box !important;
//     -webkit-transition: all 0.3s ease !important;
//     transition: all 0.3s ease;
//     font-size: 1.6em !important;
//     background-color: #a4c350 !important;
//     color: rgb(249, 248, 248) !important;
//     border: 1px solid #a4c350 !important;
//     box-shadow: 0 0 2px 0px #333 !important;
//     border-radius: 50% !important;
//     border: none !important;
//     padding: 0 !important;
//     width: 50px !important;
//     height: 50px !important;
//     min-width: 50px !important;
//     line-height: 50px !important;
//     -webkit-align-self: center !important;
//     -ms-flex-item-align: center !important;
//     align-self: center !important;
//     cursor: pointer !important;
//     outline: none !important;
// }

.kdCslO {
	display: none !important;
}


#deanEmail {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	cursor: pointer;
}


// .rec-dot{
// 	    background-color: #fffffd !important;
// 		box-shadow: 0 0 1px 2px rgb(112 111 111 / 34%) !important;
// }
// .rec-dot:active{
// 	background-color: #A6C550 !important;
// 	box-shadow: 0 0 1px 2px #A6C550 !important;
// }
.krmNah {
	box-sizing: border-box;
	padding: 0;
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	border: none;
	margin: 5px;
	background-color: #fefefd !important;
	font-size: 1.3em;
	content: "";
	height: 10px;
	width: 10px;
	box-shadow: 0 0 1px 3px #A6C550 !important;
	border-radius: 50%;
	outline: none;
}

#dni-link:hover {
	color: #a6c550 !important;
}

@media (min-width: 300px) and (max-width: 1024px) {
	.mb-cls {
		margin: auto !important;
	}

	.mb-uses {
		width: 100% !important;
		height: 22px !important;
	}

	.mb-p-txt {
		width: 100% !important;
		height: 100% !important;
	}
}
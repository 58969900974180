@media (min-width: 1030px) {
  .navbar {

    /* padding-left: 166px; */
  }
}

@media (min-width: 992px) {
  body {
    margin-top: 80px;
  }

  .navbar {
    height: 80px;
  }

  .dropdown-item:active {
    color: #585858 !important;
    text-decoration: dotted;
    background-color: white !important;
  }

  .bg-primary {
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    z-index: 999 !important;
    background: #fff !important;
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 7%) !important;
  }

  .nav-link {
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 1.2 !important;
    color: #585858 !important;
  }

  .nav-link:hover {
    color: rgb(166, 197, 80) !important
  }

  .headerLogo {
    width: 179px;
    height: 490x;
  }

  .navbar-dark .navbar-toggler {
    background: rgb(166, 197, 80) !important;
    color: white !important
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
    margin-top: 4px;
    padding-top: 6px;
    padding-bottom: 10px;
  }

  .dropdown-menu .dropdown-toggle:after {
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid;
  }

  .dropdown-menu .dropdown-menu {
    margin-left: 0;
    margin-right: 0;
  }

  .dropdown-menu li {
    position: relative;
  }

  .nav-item .submenu {
    display: none;
    position: absolute;
    left: 101%;
    top: -7px;
  }

  .nav-item .submenu-left {
    right: 100%;
    left: auto;
  }

  .dropdown-menu>li:hover {
    background-color: #f1f1f1
  }

  .dropdown-menu>li:hover>.submenu {
    display: block;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem !important;
    padding-left: 2.5rem !important;
  }

  .nav-item .active {
    background-color: white !important;
    color: rgb(166, 197, 80) !important;
  }
}

@media (min-width: 320px) {
  /* .nav-link{
      font-weight: 700 !important;
      font-size: 14px !important;
      line-height: 1.2 !important;
      color: #585858 !important;
  } */

  .nav-link:hover {
    color: rgb(166, 197, 80) !important
  }

  .headerLogo {
    width: 179px;
    height: 490x;
  }

  .bg-primary {
    background: #fff !important;
  }

  .navbar-dark .navbar-toggler {
    background: rgb(166, 197, 80) !important;
    color: white !important
  }

  .navbar-nav .dropdown-menu {
    position: static;
    float: none;
    border-radius: 0px;
    border-top: 4px solid rgb(166, 197, 80)
  }

  .dropdown-menu .dropdown-toggle:after {
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid;
  }

  .dropdown-menu .dropdown-menu {
    margin-left: 0;
    margin-right: 0;
    border-radius: 0px;
    border-top: 4px solid rgb(166, 197, 80)
  }

  .dropdown-menu li {
    position: relative;
  }

  .nav-item .submenu {
    position: absolute;
    /* left: 20%; */
    /* top: 155px; */
    width: 90%;
    border-right: 0px
  }

  .nav-item .submenu-left {
    right: 100%;
    left: auto;
  }

  .dropdown-menu>li:hover {
    background-color: #f1f1f1
  }

  .dropdown-menu>li:hover>.submenu {
    display: block;
  }

}



.rd-navbar-nav li a {
  position: relative;
  display: block;
  background: transparent;
  transition: 0.3s all ease;
}

.rd-navbar-nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  /* display: flex; */
  /* justify-content: center; */
  background-color: #ffffff;
  box-shadow: 0 0 13px -1px rgba(0, 0, 0, 0.17);
}

/* .navbar{
  height: 96px;
} */
.navbar-brand img {
  height: 45px !important;
  width: 200px !important;
}

.header-btn {
  border-radius: 0 !important;
}


.dropdown-text {
  color: #585858 !important;
  transition: 0.9s all ease !important;
}

.dropdown-text:hover {
  color: #A7CA56 !important;
  padding-left: 40px !important;
}

.dropdown-text:hover .dott {
  display: block;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown:hover {
  color: #A7CA56 !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 5;
  width: 270px;
  border-top: 4px solid #A7CA56;
  color: #585858;
}

.dropdown-content a {
  color: black;
  padding: 5px 16px;
  text-decoration: none;
  display: block;
  transition: 0.9s all ease-in;
}

.dropdown-content-1 {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 5;
  width: 270px;
  border-top: 4px solid #A7CA56;
  color: #585858;
  margin-left: 159px;
  top: 168px;
}

.dropdown-content-1 a {
  color: black;
  padding: 5px 16px;
  text-decoration: none;
  display: block;
  transition: 0.9s all ease-in;
}

.dropdown-content-2 {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 5;
  width: 270px;
  border-top: 4px solid #A7CA56;
  color: #585858;
  margin-left: 159px;
  top: 138px;
}

.dropdown-content-2 a {
  color: black;
  padding: 5px 16px;
  text-decoration: none;
  display: block;
  transition: 0.9s all ease-in;
}

.dropdown-menu {
  border: 1px solid #ffffff;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-1:hover .dropdown-content-1 {
  display: block;
}

.dropdown-2:hover .dropdown-content-2 {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}





.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding-top: 42px;
}

.sidenav .mobile-drpdn1 {
  top: 140px;
  left: 60px;
}

.sidenav .mobile-dropdown {
  top: 74px;
  left: 60px;
}

.sidenav a {
  text-decoration: none;
  font-size: 14px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav .dropdown-menu {
  border-top: 2px solid #A7CA56 !important;
  border: 1px solid #dddddd;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: -12px;
  right: 1px;
  height: 20px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }

}

.bor-right {
  border-right: 1px solid #f3f3f3 !important;
}

.sidenav .mobile-drpdn {
  top: 115px;
  left: 60px;
  width: 165px
}

.sidenav .mobile-drpdn1 {
  top: 135px;
  left: 60px;
  width: 165px
}

.bg-white {
  background-color: #fff !important;
  box-shadow: 0 0 13px -1px rgba(0, 0, 0, 0.17) !important;
}

@media all and (min-width: 992px) {
  .dropdown-menu li {
    position: relative;
  }

  .nav-item .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
  }

  .nav-item .submenu-left {
    right: 100%;
    left: auto;
  }

  .dropdown-menu>li:hover {
    background-color: #f1f1f1
  }

  .dropdown-menu>li:hover>.submenu {
    display: block;
  }
}

/* ============ desktop view .end// ============ */

/* ============ small devices ============ */
@media (max-width: 991px) {
  .dropdown-menu .dropdown-menu {
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    margin-bottom: .5rem;
  }
}


.av-composition-mobile {
  position: absolute !important;
  margin-left: 12px !important;
  border-top: 2px solid #A7CA56 !important;
  border-bottom: 1px solid #8080804a !important;
  border-left: 1px solid #8080804a !important;
  border-right: 1px solid #8080804a !important;
  margin-top: -8px !important;
  background: white !important;
}

.av-nutrient-mobile {
  position: absolute !important;
  margin-left: 12px !important;
  border-top: 2px solid #A7CA56 !important;
  border-bottom: 1px solid #8080804a !important;
  border-left: 1px solid #8080804a !important;
  border-right: 1px solid #8080804a !important;
  margin-top: -8px !important;
  background: white !important;
}

.dropdown-3:hover .dropdown-content-3 {
  display: block;
}

.dropdown-content-3 {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 5;
  width: 270px;
  border-top: 4px solid #A7CA56;
  color: #585858;
  margin-left: 159px;
  top: 80px;
}

.dropdown-content-3 a {
  color: black;
  padding: 5px 16px;
  text-decoration: none;
  display: block;
  transition: 0.9s all ease-in;
}
//
// RD Navbar Centered
// --------------------------------------------------

.rd-navbar-centered {

  // RD Navbar Centered Fixed
  // --------------------------------------------------
  &.rd-navbar-fixed {
    .rd-navbar-toggle {
      z-index: 99;
    }
    .rd-navbar-nav-wrap {
      z-index: -1;
    }
  }

  // RD Navbar Centered Fullwidth
  // --------------------------------------------------
  &.rd-navbar-fullwidth {
    .rd-navbar-inner {
      max-width: 970px;
    }

    .rd-navbar-nav {
      > li {
        > a {
          &:after {
            right: -20px;
          }
        }

        &+ li {
          margin-left: 37px;
        }
      }
    }
  }

  // RD Navbar Centered Static
  // --------------------------------------------------
  &.rd-navbar-static {
    .rd-navbar-inner {
      max-width: 1200px;
    }

    .rd-navbar-nav {
      > li {
        > a {
          &:after {
            right: -30px;
          }
        }

        &+ li {
          margin-left: 57px;
        }
      }
    }
  }


  // RD Navbar Centered Fullwidth and Static
  // --------------------------------------------------
  &.rd-navbar-fullwidth,
  &.rd-navbar-static {
    .rd-navbar-inner {
      padding-top: 30px;
      padding-bottom: 35px;
      transition: .3s all ease;
    }

    .rd-navbar-nav {
      > li {
        vertical-align: bottom;

        > a {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            top: 50%;
            height: 22px;
            width: 1px;
            background: $gray-lighter;
            transform: translateY(-50%);
          }
        }

        &:last-child, &:nth-child(4) {
          a:after {
            display: none;
          }
        }

        > .rd-navbar-dropdown {
          width: 250px;
          margin-top: 35px;
        }
      }

      .rd-navbar-brand {
        margin-top: -3px;

        .icon {
          font-size: 30px;
          line-height: 1;
        }

        .icon + img {
          padding-top: 2px;
        }
      }
    }

    &.rd-navbar--is-stuck {
      .rd-navbar-inner {
        padding-top: 25px;
        padding-bottom: 25px;
      }

      .rd-navbar-nav {
        > li {
          &:nth-child(5) {
            display: none;
          }
          &:nth-child(4) a:after {
            display: inline-block;
          }

          > .rd-navbar-dropdown {
            margin-top: 25px;
          }
        }
      }
    }
  }
}
.head-logo{
    width: 150px;
}
.main-heading{
    font-size: 26px;
    font-family: Calibri;
    font-weight: 700;
    color: #000;
}
.sub-heading{
    font-size: 17px;
    color: #000;
    font-family: Calibri;
    font-weight: 600;
}
.border-tab{
        border-left: 1px solid #000!important;
        border-right: 1px solid #000!important;
        border-bottom: 1px solid #000!important;
    }
    .border-tab-1 {
        border-left: 1px solid #000!important;
        /* border-right: 1px solid #000!important; */
        border-bottom: 1px solid #000!important;
    }

.border-btm{
    border-bottom: 1px solid #000;
}
.row{
    color: #000!important;
    font-family: Calibri;
    font-weight: 500;
}
.uses{
    width: 50px;
}
.first-line{
    font-size: 18px;
    font-weight: 700;
}

#user_guide_link{
    color: #15678F !important;
}
#user_guide_link:hover{
    color: #A7CA56 !important;
}
button#Guide-download {
    margin-left: 600px;
    position: relative;
    margin-bottom: -100px;
    z-index: 999;
}
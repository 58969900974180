//
// Jumbotron Custom
// --------------------------------------------------

.jumbotron-custom {

  &-title {
    font-size: 80px;
    line-height: 1;
  }

  @include media-breakpoint-up(md) {
    padding: 30px;
    border: 1px solid $white;

    &-title {
      font-size: 100px;
    }
  }
  @include media-breakpoint-up(lg) {
    &-title {
      font-size: 120px;
    }
  }
  @include media-breakpoint-up(xl) {
    min-height: 491px;

    &-title {
      font-size: 180px;
    }
  }

  &-variant-2 {
    @include media-breakpoint-up(md) {
      min-height: 421px;
    }
    @include media-breakpoint-up(xl) {
      min-height: 521px;
    }
  }

  &-variant-3 {
    @include media-breakpoint-up(md) {
      min-height: 371px;
      padding-top: 85px;
    }
    @include media-breakpoint-up(xl) {
      padding-top: 70px;
    }
  }
}
//
// Responsive unit
// --------------------------------------------------

@include unit-responsive(20px, 30px);

// Unit Spacing
//

.unit-spacing-xs {
	@include unit-spacing(15px, 0);
}

.unit-spacing-sm {
	@include unit-spacing(10px, 18px);
}

.unit-spacing-md {
	@include unit-spacing(15px, 23px);
}

@import './../../node_modules/bootstrap/scss/functions';
@import './../../node_modules/bootstrap/scss/variables';
@import './../../node_modules/bootstrap/scss/mixins';
@import 'variables-custom';
@import 'mixins';
@import 'reset';

@import 'components/text-alignment';

@import './fonts.scss';

@import 'components/type-custom.scss';
@import 'components/alerts-custom.scss';
@import 'components/text-styling';
@import 'components/buttons-custom';
@import 'components/icons';
@import 'components/thumbnails-custom';
@import 'components/forms-custom';
@import 'components/unit-responsive';
@import 'components/sections';
@import 'components/groups';
@import 'components/scaffolding-custom';
@import 'components/context-styling';
@import 'components/offsets';
@import 'components/quotes';
@import 'components/tooltip-custom';
@import 'components/progress-bars-custom';
@import 'components/nav-custom';
@import 'components/boxes';
@import 'components/page-loader';
@import 'components/jumbotron-custom';
@import 'components/dividers';
@import 'components/posts';
@import 'components/pagination-custom';
@import 'components/products';
@import 'components/tables-cutsom';
@import 'components/links';
@import 'components/panels-custom';
@import 'components/one-page';
@import 'components/utilities-custom';
@import 'components/gallery-item';

@import 'plugins/animate.scss';
@import 'plugins/isotope.scss';
@import 'plugins/owl-carousel.scss';
@import 'plugins/rd-navbar.scss';
@import 'plugins/rd-navbar-default.scss';
@import 'plugins/rd-navbar-transparent.scss';
@import 'plugins/rd-navbar-centered.scss';
@import 'plugins/rd-parallax.scss';
@import 'plugins/rd-google-map.scss';
@import 'plugins/swiper.scss';
@import 'plugins/ui-to-top.scss';
@import 'plugins/responsive-tabs.scss';
@import 'plugins/photoswipe';
@import 'plugins/rd-search';
@import 'plugins/rd-flickrfeed';
@import 'plugins/progress-bar';
@import 'plugins/counter';
@import 'plugins/timecircles';
@import 'plugins/rd-video-player';
@import 'plugins/_jquery.fs.stepper';
@import 'plugins/jquery-circle-progress';
@import 'plugins/card-custom';
@import 'plugins/material-parallax';
@import 'plugins/lightgallery';

.select2-selection {
    display: none I !important;
}

.select2 .select2-container .select2-container--bootstrap {
    display: none !important;
}

.selection {
    display: none !important;
}

.form-control {
    display: block !important;
    width: 100% !important;
    height: 46px !important;
    padding: 12px 18px !important;
    font-size: 14px !important;
    line-height: 1.4285714286 !important;
    color: #585858 !important;
    background-color: #fff !important;
    background-image: none !important;
    border: 1px solid #f0f0f0 !important;
    border-radius: 0 !important;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%) !important;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s !important;
}

//================================================================
.bg-info {
    background-color: #F70A0A !important;
    color: whitesmoke !important;
}

.bg-info-active {
    background-color: hsl(0, 84%, 83%) !important;
    color: whitesmoke !important;
}

//=====================================================================



.bg-success-active {
    background-color: #a6c550 !important;
    color: whitesmoke !important;
}

.bg-success {
    background-color: rgb(166 197 80 / 66%) !important;
    color: whitesmoke !important;
}

//========================================================================


.bg-warning {
    background-color: #f5d181 !important;
    // margin: 4px !important;
    color: whitesmoke !important;
}


.bg-warning-active {
    background-color: #ef842D !important;
    color: whitesmoke !important;
}


//===========================================================================================
.bg-obesity {
    background-color: rgb(228 155 247 / 78%) !important;
    // margin: 4px !important;
    color: whitesmoke !important;
}


.extreame-danger {
    background-color: #f70a0a8f !important;
    color: whitesmoke !important;
}

.extreame-danger-active {
    background-color: #F70A0A !important;
    color: whitesmoke !important;
}

.bg-obesity-active {
    background-color: #d864f6 !important;
    color: whitesmoke !important;
}


//================================================================================================
.bg-ext-danger-active {
    background-color: #ed0f68 !important;
    color: whitesmoke !important;
}

.bg-ext-danger {
    background-color: #f570a554 !important;
    // margin: 4px !important;
    color: whitesmoke !important;
}


.stepper {
    position: relative !important;
    display: inline-block !important;
    max-width: 200px;
    width: 169px !important;
    margin-bottom: 15px !important;
    margin-left: 15px !important;
    vertical-align: middle !important;
}

.input-group-text {
    display: flex !important;
    align-items: center !important;
    padding: 0.375rem 0.75rem !important;
    margin-bottom: 0 !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #495057 !important;
    text-align: center !important;
    white-space: nowrap !important;
    height: 46px !important;
    background-color: #e9ecef !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
}

#paymentContainer {
    max-height: 476px !important;
    overflow: auto !important;
}

#myAccountUl .rd-navbar-dropdown {
    margin-top: 0px !important;
    margin-left: 800px;
}

.btn-warning {
    color: whitesmoke !important;
    background-color: #878581 !important;
    border-color: #878581 !important;
}

.btn-warning:hover {
    color: whitesmoke !important;
    background-color: #15678f !important;
    border-color: #15678f !important;
}

.shadow1 {
    box-shadow: 1px 1rem 9rem 20px rgb(60 59 59 / 15%) !important;
}

.demo {
    margin-top: -25px !important
}

img#newsImages {
    height: 165px;
    width: 325px;
    padding: 4px;
}

#newsCatURL {
    cursor: pointer;
    font-size: 14px;
}

#newsCatURL:hover {
    color: #a4c350;
}






#moreNews {
    color: #126E94;
    cursor: pointer;
}

#newsCatURL1 {
    color: #126E94;
    cursor: pointer;
}

#moreNews:hover {
    // color: #a4c350;
    font-size: 15px;
}

#newsCatURL1:hover {
    color: #a4c350 !important;
}


button.rec-arrow-left {
    background: #a4c350 !important;
    color: white !important;
    border: 1px solid #a4c350 !important;
}

button.rec-arrow-right {
    background: #a4c350 !important;
    color: white !important;
    border: 1px solid #a4c350 !important;
}

button.jhmYzC {
    box-sizing: border-box !important;
    padding: 0 !important;
    -webkit-transition: all 250ms ease !important;
    transition: all 250ms ease !important;
    border: none !important;
    margin: 5px !important;
    background-color: transparent;
    font-size: 1.3em !important;
    content: "" !important;
    height: 10px !important;
    width: 10px !important;
    box-shadow: 0 0 1px 2px rgba(36, 60, 156, 0.32) !important;
    border-radius: 50% !important;
    outline: none !important;
}

button.jhmYzC:hover {
    box-shadow: 0 0 1px 2px rgba(36, 60, 156, 0.32) !important;
}

.rec-pagination {
    display: none !important;
}

#oral_adverse_effects_hr:hover {
    color: #a4c350 !important;
    cursor: pointer !important;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05) !important;
    padding: 0px !important;
    // line-height: 1px !important;
}

td {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

#table_lab {
    // height: 100%;
    // overflow-x: scroll;
    // overflow-y: scroll;
    display: block;
    // table-layout: auto;
    height: 600px;
    // overflow: auto;
    overflow-x: auto;
    overflow-y: auto;


    // display: "block",
    // height: "600px",
    // overflowY: "auto",
}

.header {
    position: sticky;
    top: 0;
    border: 0px !important;
    background: rgb(216, 215, 215) !important;
}

#table_insulin {
    // height: 100%;
    // overflow-x: scroll;
    // overflow-y: scroll;
    display: block;
    // table-layout: auto;
    height: 600px;
    width: 100%;
    // overflow: auto;
    overflow-x: auto;
    overflow-y: auto;


    // display: "block",
    // height: "600px",
    // overflowY: "auto",
}





.results tr[visible='false'],
.no-result {
    display: none !important;
    color: white !important;
    border: 0px solid rgb(244, 244, 244) !important;
}

.results tr[visible='true'] {
    display: table-row !important;

    #whiteBorder {
        color: black !important;
        border-bottom: 1px solid rgba(228, 227, 227, 0.29) !important;
    }

    #whiteBorderGray {
        color: black !important;
        border-bottom: 1px solid rgba(228, 227, 227, 0.29) !important;
    }
}

#whiteBorder {
    border: 0px solid #dee2e6 !important;
}

#whiteBorderGray {
    border: 0px solid white !important;
    background: white !important;
}

#whiteBorderGray:hover {
    border: 0px solid white !important;
    background: white !important;
}

#whiteBorder:hover {
    border: 0px solid #dee2e6 !important;
    background: #ffffff !important;
    ;

}

#exp_urls:hover {
    color: #126E94;
    text-decoration: underline;
}

#canada_lab .table th,
.table td {
    padding: 0px !important;
    padding-left: 10px !important;
    vertical-align: top !important;
    border-top: 1px solid #dee2e6 !important;
    margin-left: 10px !important;
}

.oral-1 {
    margin-left: 2px !important;
}

.other-1 {
    margin-left: -9px !important;
}

.lab-1 {
    margin-left: -2px !important;
}

.dose-1 {
    margin-left: -4px !important;
}

.cit-1 {
    margin-left: -1px !important;
}

@media (min-width: 300px) and (max-width: 1200px) {

    #cals-1 {
        display: none !important;
    }

    #Tables-1 {
        display: none !important;
    }

    #myAccount-1 {
        display: none !important;
    }
}

@media (max-width: 1200px) {

    #cals-1 {
        display: none !important;
    }

    #Tables-1 {
        display: none !important;
    }

    #myAccount-1 {
        display: none !important;
    }
}

@media (max-width: 576px) {
    .mobile-fixed-width {
        width: 200px;
    }


}


@media (min-width: 300px) and (max-width: 990px) {

    .rd-navbar-fixed .rd-navbar-nav li:hover {
        color: #ffffff !important;
    }

    .dropdown-menu.show {
        display: block !important;
        margin-left: -4px !important;
        border-radius: 0px !important;
        border-top: 3px solid #a9c756 !important;
    }

    .dropdown-menu.show a {
        font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
        font-size: 14px !important;
        line-height: 0.785714286 !important;
        font-weight: 400 !important;
        color: #212121d9 !important;
    }

    .uss-ico {
        width: 70px !important;
        height: 40px;
    }

    .uss-of-uss {
        margin-left: 18px !important
    }

    // .add-fev {
    //     margin-left: 37px !important;
    //     display: block !important;
    //     float: left !important;
    //     font-size: 12px !important;
    //     margin-top: 70px !important;
    //     width: 200px !important
    // }

    // .add-Customize {
    //     margin-top: 5px !important;
    //     margin-left: 60px;
    //     width: 200px !important
    // }
    .tab-width {
        width: 283px;
    }

    .downlod-pdf-c {
        margin-left: 60px !important;
        float: left !important;
        margin-top: 5px !important;
        font-size: 12px !important;
        width: 200px !important
    }

    .add-fevf {
        margin-left: 35px !important;
        display: block !important;
        float: left !important;
        font-size: 12px !important;
        margin-bottom: 10px !important;
    }

    .downlod-pdf-cf {
        margin-left: 200px !important;
        float: left !important;
        margin-top: -48px !important;
        font-size: 12px !important;
    }

    .main-header-app {
        margin-top: 110px !important;
    }
}

.rd-navbar-fixed {
    display: block !important;
}

.rd-navbar-fixed .rd-navbar-panel {
    display: flex !important;
    align-items: center !important;
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    padding: 4px !important;
    height: 56px !important;
    color: #585858 !important;
    z-index: 999 !important;
}

.rd-navbar-fixed .rd-navbar-nav-wrap.active {
    transform: translateX(0) !important;
}

.rd-navbar-fixed .rd-navbar-nav-wrap {
    position: fixed !important;
    top: -56px !important;
    left: 0 !important;
    width: 232px !important;
    padding: 112px 0 56px !important;
    bottom: -56px !important;
    color: #fff !important;
    background: #fff !important;
    z-index: 15 !important;
    transform: translateX(-120%);
}


.rd-navbar-fixed .rd-navbar-brand {
    display: block !important;
    text-align: left !important;
    position: fixed !important;
    top: 3px !important;
    left: 50% !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    font-size: 22px !important;
    line-height: 46px !important;
    height: 48px !important;
    z-index: 17 !important;
    transform: translateX(-50%) !important;
}

.rd-navbar-fixed .rd-navbar-nav {
    display: block !important;
    height: 100% !important;
    overflow: auto !important;
    font-size: 16px !important;
    line-height: 26px !important;
    text-align: left !important;
}

.rd-navbar-fixed .rd-navbar-nav li,
.rd-navbar-sidebar .rd-navbar-nav li {
    position: relative !important;
    display: block;
    width: 100% !important;
}

.thera-img {
    width: 40px !important;
    height: 40px !important
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
    margin: auto;
}



@media (min-width: 300px) and (max-width: 520px) {
    .min-screen {
        width: 100%;
    }

    // .add-fev {
    //     margin-left: 31px !important;
    //     display: block !important;
    //     float: left !important;
    //     font-size: 12px !important;
    //     margin-top: 70px !important;
    //     width: 200px !important
    // }

    // .add-Customize {
    //     margin-top: 5px !important;
    //     margin-left: 23px !important;
    //     width: 198px !important
    // }

    .downlod-pdf-c {
        margin-left: 60px !important;
        float: left !important;
        margin-top: 5px !important;
        font-size: 12px !important;
        width: 200px !important
    }

}

@media (min-width: 425px) and (max-width: 990px) {

    // .add-fev {
    //     margin-left: 60px !important;
    //     display: block !important;
    //     float: left !important;
    //     font-size: 12px !important;
    //     margin-top: 70px !important;
    //     width: 200px !important
    // }

    // .add-Customize {
    //     margin-top: 5px !important;
    //     margin-left: -20px;
    //     width: 200px !important
    // }

    .downlod-pdf-c {
        margin-left: 60px !important;
        float: left !important;
        margin-top: 5px !important;
        font-size: 12px !important;
        width: 200px !important
    }

}

@media (min-width: 768px) and (max-width: 990px) {

    .add-fev {
        margin-left: 20px !important;
        display: block !important;
        float: left !important;
        font-size: 12px !important;
        margin-top: 70px !important;
        width: 200px !important
    }

    .add-Customize {
        margin-top: 70px !important;
        margin-left: -20px;
        width: 200px !important
    }

    .downlod-pdf-c {
        margin-left: 0px !important;
        float: left !important;
        margin-top: 70px !important;
        font-size: 12px !important;
        width: 200px !important
    }

}

.wiper-slide1 {
    background-image: url("../assets/home/first_slider.jpg")
}

.wiper-slide2 {
    background-image: url("../assets/home/second_slider.jpg")
}

.wiper-slide3 {
    background-image: url("../assets/home/third_slider.jpg")
}

.activeNew {
    color: rebeccapurple !important;
}

// @media print {
//     footer {page-break-after: always !important;}
//   }

#c-toc:hover {
    color: #a4c350 !important;
}

.contain {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
}

.item {
    align-items: center;
    // background-color: tomato;
    // color: white;
    // display: flex;
    height: 300px;
    justify-content: left;
}

.owl-dots {
    display: block !important;
}

.owl-prev,
.owl-next {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    user-select: none;
    display: none !important;
}

// body {
//     margin: 100px 0;
//     padding: 30px;
//   }
.html5-video-player {
    height: 303px !important;
    width: 539px !important;
}

#player {
    height: 303px !important;
    width: 539px !important;
}

.player-wrapper {
    height: 302px !important;
    width: 100% !important;

}
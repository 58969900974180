/*
 Row Offsets
 */

.row-45 {
	margin-bottom: -45px;
	& > * {
		margin-bottom: 45px;
	}
}

/*
 Custom Blocks
 */

.block-custom-01 {
	padding-left: 20px;
	padding-right: 20px;
}
//
// Formstone Stepper Plugin
// --------------------------------------------------

.stepper{
  position: relative;
  display: inline-block;
  max-width: 70px;
  width: 70px;
  margin-bottom: $form-wrap-margin-bottom;
  margin-left: 15px;
  vertical-align: middle;

  input[type="number"]{
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0;
    text-align: center;
    -moz-appearance: textfield;
  }

  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &-arrow{
    position: absolute;
    top: 50%;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    font-family: "FontAwesome";
    cursor: pointer;
    color: #b7b7b7;
    transition: .3s all ease;

    &:hover{
      color: $primary;
    }

    &.up{
      right: -1px;
      text-align: left;

      &:before{
        content: "\f105";
      }
    }

    &.down{
      left: -1px;
      text-align: right;

      &:before{
        content: "\f104";
      }
    }
  }

  &.disabled{
    .stepper-arrow{
      pointer-events: none;
      opacity: .5;
    }
  }
}


//
// Sections
// --------------------------------------------------

.section-relative {
  position: relative;
}

.section-hidden {
  overflow: hidden;
}

.section-cover {
  min-height: 100vh;
}

.slider-menu-position {
  position: relative;

  .rd-navbar-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

// Section Fluid Grid Demonstration
// -------------------------------

.section-fluid-grid-demonstration {
  [class*="col-"] {
    padding: 20px;
    border: 1px solid $brand-gallery;

    & + [class*="col-"] {
      border-top: none;
      border-left: none;
    }

    @include media-breakpoint-down(lg) {
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
    }
    @media (max-width: $screen-xs-min - 1) {
      padding-left: 5px;
      padding-right: 5px;
    }

    @include media-breakpoint-up(lg) {
      padding: 25px 39px 32px;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 42px;
      padding-right: 42px;
    }
  }
}

// Section Boxed Grid Demonstration
// -------------------------------

.section-boxed-grid-demonstration {
  [class*="col-"] {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid $brand-gallery;

    @include media-breakpoint-down(lg) {
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
    }
    @media (max-width: $screen-xs-min - 1) {
      padding-left: 5px;
      padding-right: 5px;
    }

    @include media-breakpoint-up(xl) {
      padding-top: 28px;
      padding-bottom: 28px;
    }
  }
}

.page {
  .section-60 + .section-60 {
    padding-top: 0;
  }
}



// One Page Section
// --------------------------------------------------

.one-page {
  position: relative;

  // One Page header and footer
  &-header, &-footer {
    position: absolute;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
  }

  &-header {
    top: 43px;
  }

  &-footer {
    bottom: 33px;
  }
}


// Section Spacing
// -------------------------

$insets: (0, 8px, 15px, 25px, 30px, 45px, 50px, 60px, 80px, 95px, 110px, 120px, 140px, 150px, 160px, 170px, 220px);

.page {
  @include indent-responsive(section, '', (padding-top, padding-bottom), $medias, $insets);
  @include indent-responsive(section, top, padding-top, $medias, $insets);
  @include indent-responsive(section, bottom, padding-bottom, $medias, $insets);
}

.section-xs { padding: 40px 0 };

@include media-breakpoint-up(md) {
  .section-xs { padding: 60px 0 };
}
//
// Custom Alert
// --------------------------------------------------


// Snackbars
// -------------------------

.snackbars {
  max-width: 280px;
  padding:  9px 16px;
  margin-left: auto;
  margin-right: auto;
  color: $white;
  text-align: left;
  background-color: $gray-darker;
  border-radius: $border-radius;
  box-shadow: $shadow-area-xs;

  .icon-xxs {
    font-size: 20px;
  }

  // padding on text
  p span:last-child {
    padding-left: 14px;
  }

  // snackbar icon on the left side
  &-left {
    display: inline-block;
    margin-bottom: 0;
  }

  // snackbar link or button on the right side
  &-right {
    display: inline-block;
    float: right;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }
  }

  @include media-breakpoint-up(sm) {
    max-width: 380px;
    padding:  14px 17px;
  }
}

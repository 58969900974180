//
// Scaffolding
// --------------------------------------------------


// Reset fonts for relevant elements
input,
button,
select,
textarea {
  outline: none;
}

// Page styles
// --------------------------------------------------

.page {
  overflow: hidden;
  background-color: $content-bg;
}

// Links

a {

  &:hover,
  &:focus {
    outline: none;
  }
}

// Horizontal rules

hr {
  margin-top: 0;
  margin-bottom: 0;
  border-top: 1px solid $hr-border;
}

.embed-responsive-16by9 {
  padding-bottom: 50.5%;
}

.img-fullwidth {
  width: 100%;
}



// Element with 50% opacity inverse
.img-semi-transparent-inverse {
  transition: .3s all ease;
  will-change: transform;

  &:hover {
    opacity: .7;
  }
}
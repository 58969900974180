/** @section Isotope */


.isotope {
  z-index: 0;
  display: block;
  transition: .3s ease;
}

.isotope-sizer {
  height: 0;
  margin-top: -1px;
  margin-bottom: 0;
}

.isotope-item {
  display: block;
  will-change: transform;
  backface-visibility: hidden;
  transition: .1s opacity ease-in;
}

.isotope-filters {
  display: flex;
  position: static;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 0;
  z-index: 1;
  .btn {
    &.active { box-shadow: none!important; }
  }

  li {
    & + li {
      margin-left: 15px;
    }
  }

  &.active { display: flex; }

  @include media-breakpoint-down(md) {
    display: none;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -10px);
    flex-direction: column;
    background-color: $white;
    //box-shadow: 0 3px 11px 0 rgba($black, .07);
    li {
      min-width: 180px;
      & + li {
        margin-left: 0;
      }
      a {
        width: 100%;
      }
    }
    .btn {
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
    }
  }


}

* + .isotope-filters {
  margin-top: 20px;
}

// Offsets
* + .isotope {
  margin-top: 28px;
}

.isotope-filters-toggle {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.close-btn {
  position: absolute;
  color: #fff;
  background-color: rgba(138, 5, 5, 0.94);
  ;
  border: 1px solid rgba(138, 5, 5, 0.94);
  ;
  border-radius: 18px;
  height: 30px;
  right: 6px;
  top: 6px;
  width: 30px;
}

.close-btn:hover {
  position: absolute;
  color: #fff;
  background-color: #05668E;
  border: 1px solid #05668E;
  border-radius: 18px;
  height: 30px;
  right: 6px;
  top: 6px;
  width: 30px;
}

.carousel-make .carousel-indicators li {
  color: #a6c550;
  height: 14px;
  width: 14px;
  background-color: #a6c550;
  border: 1px solid #a6c550;
  border-radius: 50%;
}

.carousel-make .name {
  font-weight: 700;
}

.carousel-indicators:before {
  border: 1px solid #000 !important;
}

.border-left {
  margin-bottom: 0;
  border: 1px solid #eaeaea;
  width: 140px;
  margin-top: 6px !important;
  margin-right: 12px;
}

.border-right {
  margin-bottom: 0;
  border: 1px solid #eaeaea;
  width: 140px;
  margin-top: 6px;
  margin-left: 12px;
}

@media screen and (min-width: 1400px) {
  .carousel-make .carousel-indicators {
    justify-content: left;
    left: -583px;
    bottom: -60px;
  }
}


#search-container {
  /* margin: auto;
  padding: 3em 0 0; */
  /* text-align: center; */
}

#search-container img {
  margin-right: 5px;
  margin-top: -2px;
}

#searchform {
  display: inline;
  font-size: 1em;
  border-radius: 8em;
  border: 0.1em solid #a6c550;
  box-shadow: 0 0 0.3em rgba(60, 60, 60, 0.4);
  padding: 9px 5px;
  background: #fff;
}

#s {
  transition: all 0.2s ease-out;
  width: 1px;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  opacity: 0;
}

#s:focus {
  width: 8em;
  opacity: 1;
}

#search-container label {
  padding-left: 1px;
  display: inline-block;
  margin-top: 0.3em;
  color: rgba(218, 208, 190, 1);
  text-shadow: 0 0 0.1em rgba(60, 60, 60, 0.3);
  position: relative;
  left: 0.1em;
}

/* Add this CSS to your stylesheet */
@media (max-width: 768px) {
  .mobile-margin-left-magnesium {
    margin-left: 13px !important;
  }
}

/* 
@media only screen and (min-width:520px) and (max-width:1440px) {}

@media only screen and (max-width: 520px) {

  .rowml22 {
    margin-left: -22px
  }

  .padright {
    padding-right: 73px;
  }

  .inputWidth324 {
    width: 324px;
  }

  .margLeft199 {
    margin-left: 199px;
  }

  .margLeft211 {
    margin-left: -205px;
  }
}

.rowml22 {
  margin-left: 0px;
}

.inputWidth324 {
  width: 495px;
}

.padright {
  padding-right: 0px;
}

.margLeft199 {
  margin-left: 363px;
}

.margLeft211 {
  margin-left: -365px;
} */
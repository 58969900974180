//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base: #000;
$gray-darker: #222;
$gray-dark: #333;
$gray: #585858;
$gray-light: #9f9f9f;
$gray-lightest: #f6f6f8;
$gray-lighter: #f3f3f3;

$primary: #a6c550;
$brand-success: #5cb85c;
$brand-info: #14a5eb;
$brand-warning: #f5be2b;
$brand-danger: #e75854;


$white: #fff;

// Derivative Colors
$brand-green-leaf: #2d750d;
$brand-gallery: #f0f0f0;
$brand-silver-chalice: #b1b1b1;

//== Scaffolding
//
//## Settings for some of the most global styles.

$content-bg: $white;
$header-bg: $white;
$footer-bg: $white;

//** Background color for `<body>`.
$body-bg: #fff;
//** Global text color on `<body>`.
$text-color: $gray-light;

//** Global textual link color.
$link-color: $primary;
//** Link hover color set via `darken()` function.
$link-hover-color: $gray;
$link-press-color: darken($link-color, 15%);
//** Link hover decoration.
$link-hover-decoration: none;


$transition-base: all .3s ease-in-out;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

// Import fonts


$font-family-sans-serif: "Open Sans", Helvetica, Arial, sans-serif;
$font-family-serif: "Oregano", Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;


$font-family-base: $font-family-sans-serif;
$font-family-sec: $font-family-serif;


// Template width (w/o units)
$template-width: 2050;

// Template width var for resizing
$template-width-min: (
	$template-width+1)*1px;
$layout-boxed-width: 1920px;


// Base FZ & LH (w/o units)
$base-text-size: 14;
$base-lh-size: 20;

$base-vm-size: (
	($base-text-size*100)/$template-width)*1vw;


$font-size-base: $base-text-size * 1px;
$font-size-large: ceil(($font-size-base * 1.25)
); // ~18px
$font-size-small: ceil(($font-size-base * 0.85)); // ~12px

$font-weight-base: 400;

//PSD FZ headers /FOR EDIT (w/o units)
$_h1-fsz: 60;
$_h2-fsz: 36;
$_h3-fsz: 30;
$_h4-fsz: 48;
$_h5-fsz: 30;
$_h6-fsz: 18;

//PSD LH headers /FOR EDIT (w/o units)
$_h1-lh: 60;
$_h2-lh: 40;
$_h3-lh: 42;
$_h4-lh: 50;
$_h5-lh: 36;
$_h6-lh: 24;


// ** < Calculated Headings values >
$h1-font-size: $_h1-fsz * 1px;
$h2-font-size: $_h2-fsz * 1px;
$h3-font-size: $_h3-fsz * 1px;
$h4-font-size: $_h4-fsz * 1px;
$h5-font-size: $_h5-fsz * 1px;
$h6-font-size: $_h6-fsz * 1px;

$h1-line-height: (
	$_h1-lh / $_h1-fsz
);
$h2-line-height: (
	$_h2-lh / $_h2-fsz
);
$h3-line-height: (
	$_h3-lh / $_h3-fsz
);
$h4-line-height: (
	$_h4-lh / $_h4-fsz
);
$h5-line-height: (
	$_h5-lh / $_h5-fsz
);
$h6-line-height: (
	$_h6-lh / $_h6-fsz
);
// ** </ Calculated Headings values >


//** Unit-less `line-height` for use in components like buttons.
$line-height-base: (
	$base-lh-size / $base-text-size
); // l-h/fz-base
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family: $font-family-serif;
$headings-font-weight: inherit;
$headings-line-height: 1.1;
$headings-color: $gray;


//== Iconography
//
//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.

//** Load fonts from this directory.
$icon-font-path: "./../../assets/fonts/";

//** File name for all font files.
$icon-font-name: "glyphicons-halflings-regular";
$icon-font-awesome: "fontawesome-webfont";
$icon-material-design: "material-design";
$icon-fl-great-icon-set: "fl-great-icon-set";

//** Element ID within SVG icon file.
$icon-font-svg-id: "glyphicons_halflingsregular";
$icon-font-awesome-id: "fontawesomeregular";
$icon-material-design-id: "materialdesigniconsregular";
$icon-fl-great-icon-set-id: "flgreaticonset";

//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical: 14px;
$padding-base-horizontal: 18px;

$padding-large-vertical: $padding-base-vertical*1.2;
$padding-large-horizontal: $padding-base-horizontal*1.2;

$padding-small-vertical: 10px;
$padding-small-horizontal: 14px;

$padding-xs-vertical: $padding-base-vertical*0.4;
$padding-xs-horizontal: $padding-base-horizontal*0.4;

$line-height-large: 1.3333333; // extra decimals for Win 8.1 Chrome
$line-height-small: 1.5;

$border-radius-base: 0;
$border-radius-large: 6px;
$border-radius-small: 3px;

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color: $gray-dark;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg: $primary;

//** Width of the `border` for generating carets that indicator dropdowns.
$caret-width-base: 4px;
//** Carets increase slightly in size for larger components.
$caret-width-large: 5px;


//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding: 8px;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding: 5px;

//** Default background color used for all tables.
$table-bg: transparent;
//** Background color used for `.table-striped`.
$table-bg-accent: #f9f9f9;
//** Background color used for `.table-hover`.
$table-bg-hover: #f5f5f5;
$table-bg-active: $table-bg-hover;

//** Border color for table and cell borders.
$table-border-color: #ddd;


//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-size: 16;
$btn-lh-size: 24;

$btn-font-weight: 400;

$btn-default-color: $white;
$btn-default-bg: $gray-base;
$btn-default-border: $gray-base;

$btn-primary-color: $white;
$btn-primary-bg: $primary;
$btn-primary-border: $primary;

$btn-success-color: $white;
$btn-success-bg: $brand-success;
$btn-success-border: $brand-success;

$btn-info-color: $white;
$btn-info-bg: $brand-info;
$btn-info-border: $brand-info;

$btn-warning-color: $white;
$btn-warning-bg: $brand-warning;
$btn-warning-border: $brand-warning;

$btn-danger-color: $white;
$btn-danger-bg: $brand-danger;
$btn-danger-border: $brand-danger;

$btn-link-disabled-color: $gray-light;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base: $border-radius-base;
$btn-border-radius-large: $border-radius-large;
$btn-border-radius-small: $border-radius-small;


//== Forms
//
//##

//** `<input>` background color
$input-bg: #fff;
//** `<input disabled>` background color
$input-bg-disabled: $gray-lighter;

//** Text color for `<input>`s
$input-color: $gray;
//** `<input>` border color
$input-border: $brand-gallery;

//** Font size for input
$input-font-size: 14px;

//** Default `.form-input` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius: $border-radius-base;
//** Large `.form-input` border radius
$input-border-radius-large: $border-radius-large;
//** Small `.form-input` border radius
$input-border-radius-small: $border-radius-small;

//** Border color for inputs on focus
$input-border-focus: $primary;

//** Placeholder text color
$input-color-placeholder: $gray-light;
$input-color-placeholder-focus: $primary;

//** Default `.form-input` height
$input-height-base: 46px;
//** Large `.form-input` height
$input-height-large: (
	ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2
);
//** Small `.form-input` height
$input-height-small: (
	floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2
);

//** `.form-wrap` margin
$form-wrap-margin-bottom: 15px;

$legend-color: $gray-dark;
$legend-border-color: #e5e5e5;

//** Background color for textual input addons
$input-group-addon-bg: $gray-lighter;
//** Border color for textual input addons
$input-group-addon-border-color: $input-border;

//** Disabled cursor for form controls and buttons.
$cursor-disabled: not-allowed;

//** Textarea default
$textarea-default-height: 106px;
$textarea-default-min-height: 50px;
$textarea-default-max-height: 370px;

//== Dropdowns
//

// $dropdown-font-size: 14;
// $dropdown-lh-size: 36;

//## Dropdown menu container and contents.

//** Background for the dropdown menu.
$dropdown-bg: #fff;
//** Dropdown menu `border-color`.
$dropdown-border: rgba(0, 0, 0, .15);
//** Dropdown menu `border-color` **for IE8**.
$dropdown-fallback-border: #ccc;
//** Divider color for between dropdown items.
$dropdown-divider-bg: #e5e5e5;

//** Dropdown link text color.
$dropdown-link-color: $gray-dark;
//** Hover color for dropdown links.
$dropdown-link-hover-color: darken($gray-dark, 5%);
//** Hover background for dropdown links.
$dropdown-link-hover-bg: #f5f5f5;

//** Active dropdown menu item text color.
$dropdown-link-active-color: $component-active-color;
//** Active dropdown menu item background color.
$dropdown-link-active-bg: $component-active-bg;

//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color: $gray-light;

//** Text color for headers within dropdown menus.
$dropdown-header-color: $gray-light;

//** Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color: #000;


//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar: 1000;
$zindex-dropdown: 1000;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
$zindex-navbar-fixed: 1030;
$zindex-modal-background: 1040;
$zindex-modal: 1050;


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs: 480px;
$screen-xs-min: $screen-xs;
$screen-phone: $screen-xs-min;

// Small screen / tablet
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
$screen-md: 992px;
$screen-md-min: $screen-md;
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
$screen-lg-desktop: $screen-lg-min;

// Extra Large screen / 4k desktop
$screen-xl: 1800px;
$screen-xl-min: $screen-xl;
$screen-xl-desktop: $screen-xl-min;

// So media queries don't overlap when required, provide a maximum
$screen-xxs-max: (
	$screen-xs-min - 1
);
$screen-xs-max: (
	$screen-sm-min - 1
);
$screen-sm-max: (
	$screen-md-min - 1
);
$screen-md-max: (
	$screen-lg-min - 1
);
$screen-lg-max: (
	$screen-xl-min - 1
);

// Define media prefix
$medias: (
	0: null,
	$screen-xs-min : xs,
	$screen-sm-min : sm,
	$screen-md-min : md,
	$screen-lg-min : lg,
	$screen-xl-min : xl
);



//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns: 12;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-sm-min;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: (
	$grid-float-breakpoint - 1
);


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet: (
	720px + $grid-gutter-width
);
//** For `$screen-sm-min` and up.
$container-sm: $container-tablet;

// Medium screen / desktop
$container-desktop: (
	940px + $grid-gutter-width
);
//** For `$screen-md-min` and up.
$container-md: $container-desktop;

// Large screen / wide desktop
$container-large-desktop: (
	1140px + $grid-gutter-width
);
//** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop;


//== Navbar
//
//##

// $navbar-font-size: 16;
// $navbar-lh-size: 18;

// Basics of a navbar
$navbar-height: 50px;
$navbar-margin-bottom: $line-height-computed;
$navbar-border-radius: $border-radius-base;
$navbar-padding-horizontal: floor(($grid-gutter-width / 2));
$navbar-padding-vertical: (
	($navbar-height - $line-height-computed) / 2
);
$navbar-collapse-max-height: 340px;

$navbar-default-color: #777;
$navbar-default-bg: #f8f8f8;
$navbar-default-border: darken($navbar-default-bg, 6.5%);

// Navbar links
$navbar-default-link-color: #777;
$navbar-default-link-hover-color: #333;
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-color: #555;
$navbar-default-link-active-bg: darken($navbar-default-bg, 6.5%);
$navbar-default-link-disabled-color: #ccc;
$navbar-default-link-disabled-bg: transparent;

// Navbar brand label
$navbar-default-brand-color: $navbar-default-link-color;
$navbar-default-brand-hover-color: darken($navbar-default-brand-color, 10%);
$navbar-default-brand-hover-bg: transparent;

// Navbar toggle
$navbar-default-toggle-hover-bg: #ddd;
$navbar-default-toggle-icon-bar-bg: #888;
$navbar-default-toggle-border-color: #ddd;


//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color: lighten($gray-light, 15%);
$navbar-inverse-bg: #222;
$navbar-inverse-border: darken($navbar-inverse-bg, 10%);

// Inverted navbar links
$navbar-inverse-link-color: lighten($gray-light, 15%);
$navbar-inverse-link-hover-color: #fff;
$navbar-inverse-link-hover-bg: transparent;
$navbar-inverse-link-active-color: $navbar-inverse-link-hover-color;
$navbar-inverse-link-active-bg: darken($navbar-inverse-bg, 10%);
$navbar-inverse-link-disabled-color: #444;
$navbar-inverse-link-disabled-bg: transparent;

// Inverted navbar brand label
$navbar-inverse-brand-color: $navbar-inverse-link-color;
$navbar-inverse-brand-hover-color: #fff;
$navbar-inverse-brand-hover-bg: transparent;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg: #333;
$navbar-inverse-toggle-icon-bar-bg: #fff;
$navbar-inverse-toggle-border-color: #333;


//== Navs
//
//##

//=== Shared nav styles
$nav-link-padding: 10px 15px;
$nav-link-hover-bg: $gray-lighter;

$nav-disabled-link-color: $gray-light;
$nav-disabled-link-hover-color: $gray-light;

//== Tabs
$nav-link-border-color: #ddd;

$nav-link-link-hover-border-color: $gray-lighter;

$nav-link-active-link-hover-bg: $body-bg;
$nav-link-active-link-hover-color: $gray;
$nav-link-active-link-hover-border-color: #ddd;

$nav-link-justified-link-border-color: #ddd;
$nav-link-justified-active-link-border-color: $body-bg;

//== Pills
$nav-pills-border-radius: $border-radius-base;
$nav-pills-active-link-hover-bg: $component-active-bg;
$nav-pills-active-link-hover-color: $component-active-color;


//== Pagination
//
//##

$pagination-color: $link-color;
$pagination-bg: #fff;
$pagination-border: #ddd;

$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $gray-lighter;
$pagination-hover-border: #ddd;

$pagination-active-color: #fff;
$pagination-active-bg: $primary;
$pagination-active-border: $primary;

$pagination-disabled-color: $gray-light;
$pagination-disabled-bg: #fff;
$pagination-disabled-border: #ddd;


//== Pager
//
//##

$pager-bg: $pagination-bg;
$pager-border: $pagination-border;
$pager-border-radius: 15px;

$pager-hover-bg: $pagination-hover-bg;

$pager-active-bg: $pagination-active-bg;
$pager-active-color: $pagination-active-color;

$pager-disabled-color: $pagination-disabled-color;


//== Jumbotron
//
//##

$jumbotron-padding: 30px;
$jumbotron-color: inherit;
$jumbotron-bg: $gray-lighter;
$jumbotron-heading-color: inherit;
$jumbotron-font-size: ceil(($font-size-base * 1.5));
$jumbotron-heading-font-size: ceil(($font-size-base * 4.5));


//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text: #3c763d;
$state-success-bg: #dff0d8;
$state-success-border: darken(adjust-hue($state-success-bg, -10), 5%);

$state-info-text: #31708f;
$state-info-bg: #d9edf7;
$state-info-border: darken(adjust-hue($state-info-bg, -10), 7%);

$state-warning-text: #8a6d3b;
$state-warning-bg: #fcf8e3;
$state-warning-border: darken(adjust-hue($state-warning-bg, -10), 5%);

$state-danger-text: #a94442;
$state-danger-bg: #f2dede;
$state-danger-border: darken(adjust-hue($state-danger-bg, -10), 5%);


//== Tooltips
//
//##

//** Tooltip max width
$tooltip-max-width: 200px;
//** Tooltip text color
$tooltip-color: #fff;
//** Tooltip background color
$tooltip-bg: $primary;
$tooltip-opacity: .9;

//** Tooltip arrow width
$tooltip-arrow-width: 5px;
//** Tooltip arrow color
$tooltip-arrow-color: $tooltip-bg;


//== Popovers
//
//##

//** Popover body background color
$popover-bg: #fff;
//** Popover maximum width
$popover-max-width: 276px;
//** Popover border color
$popover-border-color: rgba(0, 0, 0, .2);
//** Popover fallback border color
$popover-fallback-border-color: #ccc;

//** Popover title background color
$popover-title-bg: darken($popover-bg, 3%);

//** Popover arrow width
$popover-arrow-width: 10px;
//** Popover arrow color
$popover-arrow-color: $popover-bg;

//** Popover outer arrow width
$popover-arrow-outer-width: (
	$popover-arrow-width + 1
);
//** Popover outer arrow color
$popover-arrow-outer-color: fade_in($popover-border-color, 0.05);
//** Popover outer arrow fallback color
$popover-arrow-outer-fallback-color: darken($popover-fallback-border-color, 20%);


//== Labels
//
//##

//** Default label background color
$label-default-bg: $gray-light;
//** Primary label background color
$label-primary-bg: $primary;
//** Success label background color
$label-success-bg: $brand-success;
//** Info label background color
$label-info-bg: $brand-info;
//** Warning label background color
$label-warning-bg: $brand-warning;
//** Danger label background color
$label-danger-bg: $brand-danger;

//** Default label text color
$label-color: #fff;
//** Default text color of a linked label
$label-link-hover-color: #fff;


//== Modals
//
//##

//** Padding applied to the modal body
$modal-inner-padding: 15px;

//** Padding applied to the modal title
$modal-title-padding: 15px;
//** Modal title line-height
$modal-title-line-height: $line-height-base;

//** Background color of modal content area
$modal-content-bg: #fff;
//** Modal content border color
$modal-content-border-color: rgba(0, 0, 0, .2);
//** Modal content border color **for IE8**
$modal-content-fallback-border-color: #999;

//** Modal backdrop background color
$modal-backdrop-bg: #000;
//** Modal backdrop opacity
$modal-backdrop-opacity: .5;
//** Modal header border color
$modal-header-border-color: #e5e5e5;
//** Modal footer border color
$modal-footer-border-color: $modal-header-border-color;

$modal-lg: 900px;
$modal-md: 600px;
$modal-sm: 300px;


//== Alerts
//
//## Define alert colors, border radius, and padding.

$alert-padding: 15px;
$alert-border-radius: $border-radius-base;
$alert-link-font-weight: bold;

$alert-success-bg: $state-success-bg;
$alert-success-text: $state-success-text;
$alert-success-border: $state-success-border;

$alert-info-bg: $state-info-bg;
$alert-info-text: $state-info-text;
$alert-info-border: $state-info-border;

$alert-warning-bg: $state-warning-bg;
$alert-warning-text: $state-warning-text;
$alert-warning-border: $state-warning-border;

$alert-danger-bg: $state-danger-bg;
$alert-danger-text: $state-danger-text;
$alert-danger-border: $state-danger-border;


//== Progress bars
//
//##

//** Background color of the whole progress component
$progress-bg: #f5f5f5;
//** Progress bar text color
$progress-bar-color: #fff;
//** Variable for setting rounded corners on progress bar.
$progress-border-radius: $border-radius-base;

//** Default progress bar color
$progress-bar-bg: $primary;
//** Success progress bar color
$progress-bar-success-bg: $brand-success;
//** Warning progress bar color
$progress-bar-warning-bg: $brand-warning;
//** Danger progress bar color
$progress-bar-danger-bg: $brand-danger;
//** Info progress bar color
$progress-bar-info-bg: $brand-info;


//== List group
//
//##

//** Background color on `.list-group-item`
$list-group-bg: #fff;
//** `.list-group-item` border color
$list-group-border: #ddd;
//** List group border radius
$list-group-border-radius: $border-radius-base;

//** Background color of single list items on hover
$list-group-hover-bg: #f5f5f5;
//** Text color of active list items
$list-group-active-color: $component-active-color;
//** Background color of active list items
$list-group-active-bg: $component-active-bg;
//** Border color of active list elements
$list-group-active-border: $list-group-active-bg;
//** Text color for content within active list items
$list-group-active-text-color: lighten($list-group-active-bg, 40%);

//** Text color of disabled list items
$list-group-disabled-color: $gray-light;
//** Background color of disabled list items
$list-group-disabled-bg: $gray-lighter;
//** Text color for content within disabled list items
$list-group-disabled-text-color: $list-group-disabled-color;

$list-group-link-color: #555;
$list-group-link-hover-color: $list-group-link-color;
$list-group-link-heading-color: #333;


//== Panels
//
//##

$panel-bg: #fff;
$panel-body-padding: 15px;
$panel-heading-padding: 10px 15px;
$panel-footer-padding: $panel-heading-padding;
$panel-border-radius: $border-radius-base;

//** Border color for elements within panels
$panel-inner-border: #ddd;
$panel-footer-bg: #f5f5f5;

$panel-default-text: $gray-dark;
$panel-default-border: #ddd;
$panel-default-heading-bg: #f5f5f5;

$panel-primary-text: #fff;
$panel-primary-border: $primary;
$panel-primary-heading-bg: $primary;

$panel-success-text: $state-success-text;
$panel-success-border: $state-success-border;
$panel-success-heading-bg: $state-success-bg;

$panel-info-text: $state-info-text;
$panel-info-border: $state-info-border;
$panel-info-heading-bg: $state-info-bg;

$panel-warning-text: $state-warning-text;
$panel-warning-border: $state-warning-border;
$panel-warning-heading-bg: $state-warning-bg;

$panel-danger-text: $state-danger-text;
$panel-danger-border: $state-danger-border;
$panel-danger-heading-bg: $state-danger-bg;


//== Thumbnails
//
//##

//** Padding around the thumbnail image
$thumbnail-padding: 4px;
//** Thumbnail background color
$thumbnail-bg: $body-bg;
//** Thumbnail border color
$thumbnail-border: #ddd;
//** Thumbnail border radius
$thumbnail-border-radius: $border-radius-base;

//** Custom text color for thumbnail captions
$thumbnail-caption-color: $text-color;
//** Padding around the thumbnail caption
$thumbnail-caption-padding: 9px;


//== Wells
//
//##

$well-bg: #f5f5f5;
$well-border: darken($well-bg, 7%);


//== Badges
//
//##

$badge-color: #fff;
//** Linked badge text color on hover
$badge-link-hover-color: #fff;
$badge-bg: $gray-light;

//** Badge text color in active nav link
$badge-active-color: $link-color;
//** Badge background color in active nav link
$badge-active-bg: #fff;

$badge-font-weight: bold;
$badge-line-height: 1;
$badge-border-radius: 10px;


//== Breadcrumbs
//
//##

$breadcrumb-padding-vertical: 8px;
$breadcrumb-padding-horizontal: 15px;
//** Breadcrumb background color
$breadcrumb-bg: #f5f5f5;
//** Breadcrumb text color
$breadcrumb-color: #ccc;
//** Text color of current page in the breadcrumb
$breadcrumb-active-color: $gray-light;
//** Textual separator for between breadcrumb elements
$breadcrumb-separator: "/";


//== Carousel
//
//##

$carousel-text-shadow: 0 1px 2px rgba(0, 0, 0, .6);

$carousel-control-color: #fff;
$carousel-control-width: 15%;
$carousel-control-opacity: .5;
$carousel-control-font-size: 20px;

$carousel-indicator-active-bg: #fff;
$carousel-indicator-border-color: #fff;

$carousel-caption-color: #fff;


//== Close
//
//##

$close-font-weight: bold;
$close-color: #000;
$close-text-shadow: 0 1px 0 #fff;


//== Code
//
//##

$code-color: #c7254e;
$code-bg: #f9f2f4;

$kbd-color: #fff;
$kbd-bg: #333;

$pre-bg: #f5f5f5;
$pre-color: $gray-dark;
$pre-border-color: #ccc;
$pre-scrollable-max-height: 340px;


//== Type
//
//##

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px;
//** Text muted color
$text-muted: $gray-light;
//** Abbreviations and acronyms border color
$abbr-border-color: $gray-light;
//** Headings small color
$headings-small-color: $gray-light;
//** Blockquote small color
$blockquote-small-color: $gray-light;
//** Blockquote font size
$blockquote-font-size: (
	$font-size-base * 1.25
);
//** Blockquote border color
$blockquote-border-color: $gray-lighter;
//** Page header border color
$page-header-border-color: $gray-lighter;
//** Width of horizontal description list titles
$dl-horizontal-offset: $component-offset-horizontal;
//** Point at which .dl-horizontal becomes horizontal
$dl-horizontal-breakpoint: $grid-float-breakpoint;
//** Horizontal line color.
$hr-border: $gray-lighter;


//== Shadows
//
//##

$shadow-area-ambient: 0px 0px 1px 0 rgba($gray-base, .07);
$shadow-area-xxs: 1px 1px 1px rgba($gray-base, .07);
$shadow-area-xs: 0 1px 4px 0 rgba($gray-base, .07);
$shadow-area-sm: 0 1px 10px 0 rgba($gray-base, .07);
$shadow-area-md: 0 3px 11px 0 rgba($gray-base, .07);
$shadow-area-lg: 0 5px 23px 0 rgba($gray-base, .07);
$shadow-area-xl: 0 12px 36px 0 rgba($gray-base, .07);

//== Flex Grid system
//
//## Define your custom flex responsive grid.

$shell-width: 300px;
$shell-xs-width: 480px;
$shell-sm-width: 750px;
$shell-md-width: 970px;
$shell-lg-width: 1200px;
$shell-xl-width: 1800px;

$range-ws: 50px;
$range-ws-sm: 30px;
$range-ws-lg: 66px;

$cell-count: 12;
$cell-spacing: 30px;
$cell-spacing-narrow: 10px;

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
	xxxl: 1800px
);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1200px
);

//== RD Navbar
//
//##

$rd-navbar-min-font-size: 24px;
$rd-navbar-min-line-height: 48px;
$rd-navbar-min-height: 56px;

$rd-navbar-color: $gray;
$rd-navbar-background: $white;
$rd-navbar-shadow: none;
$rd-navbar-width: 1200px;

//RD Navbar panel
$rd-navbar-panel-color: $rd-navbar-color;
$rd-navbar-panel-background: $rd-navbar-background;

//RD Navbar nav
$rd-navbar-nav-min-width: 270px;
$rd-navbar-nav-color: $rd-navbar-color;
$rd-navbar-nav-background: transparent;
$rd-navbar-nav-hover-color: #FFF;
$rd-navbar-nav-hover-background: $primary;
$rd-navbar-nav-active-color: $rd-navbar-nav-hover-color;
$rd-navbar-nav-active-background: $rd-navbar-nav-hover-background;

//RD Navbar dropdown
$rd-navbar-dropdown-width: 270px;
$rd-navbar-dropdown-background: $white;
$rd-navbar-dropdown-item-color: $gray;
$rd-navbar-dropdown-item-background: transparent;
$rd-navbar-dropdown-item-hover-color: $primary;
$rd-navbar-dropdown-item-hover-background: transparent;
$rd-navbar-dropdown-item-active-color: $rd-navbar-dropdown-item-hover-color;
$rd-navbar-dropdown-item-active-background: $rd-navbar-dropdown-item-hover-background;

//RD Navbar megamenu
$rd-navbar-megamenu-gutter: 30px;
$rd-navbar-megamenu-background: $white;
$rd-navbar-megamenu-item-color: $gray;
$rd-navbar-megamenu-item-background: transparent;
$rd-navbar-megamenu-item-hover-color: $primary;
$rd-navbar-megamenu-item-hover-background: transparent;

//RD Navbar togles
$rd-navbar-toggle-preset: "button-lines-arrow-2";
$rd-navbar-search-toggle-preset: "search-preset-1";
$rd-navbar-collapse-toggle-preset: "collapse-preset-1";

//RD Navbar Fixed
$rd-navbar-fixed-height: $rd-navbar-min-height;
$rd-navbar-fixed-line-height: $rd-navbar-min-line-height;
$rd-navbar-fixed-font-size: $rd-navbar-min-font-size;
$rd-navbar-fixed-panel-color: $gray;
$rd-navbar-fixed-toggle-color: $gray;
$rd-navbar-fixed-panel-background: $white;
$rd-navbar-fixed-shadow: 0 0 13px -1px rgba(0, 0, 0, 0.17);

//RD Navbar fullwidth indents
$navbar-fullwidth-nav-indent: 0;


//== RD Video Player
//
//##

$rd-video-player-controls-color: $white;
$rd-video-player-title-color: $white;
$rd-video-player-volume-bar-background: $white;
$rd-video-player-overlay-background: $gray-base;
//** video title
$rd-video-player-title-size: 13px;
$rd-video-player-title-weight: 700;
//** fullscreen icon
$rd-video-player-fullscreen-size: 18px;
$rd-video-player-fullscreen-line-height: 16px;
$rd-video-player-fullscreen-sm-size: 20px;
$rd-video-player-fullscreen-sm-line-height: 15px;
//** video time
$rd-video-time-size: 13px;
//** video play-pause controls
$rd-video-play-pause-size: 20px;
$rd-video-play-pause-controls-width: 28px;
$rd-video-play-pause-controls-height: 28px;
$rd-video-play-pause-controls-offsets: 0 5px;
$rd-video-play-pause-controls-line-height: 24px;
$rd-video-play-pause-controls-border: 2px solid rgba($white, 0.5);
//** 768
$rd-video-play-pause-sm-size: 24px;
$rd-video-play-pause-controls-sm-width: 36px;
$rd-video-play-pause-controls-sm-height: 36px;
$rd-video-play-pause-controls-sm-offsets: 0;
$rd-video-play-pause-controls-sm-line-height: 32px;
//** video icons controls
$rd-video-player-volume-icon: '\e195';
$rd-video-player-play-pause-icon: '\e0f2';
$rd-video-player-fullscreen-icon: '\e08d';
//** video progress bar
$rd-video-progress-bar-background: $white;
$rd-video-progress-bar-width: 100%;
$rd-video-progress-bar-height: 8px;
$rd-video-progress-bar-offsets: 0 10px 0 10px;
$rd-video-progress-bar-sm-offsets: 0 17px 0 18px;
//** video top controls
$rd-video-top-controls-padding: 10px 10px;
$rd-video-top-controls-xs-padding: 23px 30px;
//** video playlist
$rd-video-playlist-color: $white;
$rd-video-playlist-size: 13px;
$rd-video-playlist-weight: 700;
//
// Circle Progress Bars
// --------------------------------------------------


.progress-bar-circle-wrap {
  position: relative;
  display: inline-block;

  > p {
    position: absolute;
    left: 50%;
    bottom: 35px;
    width: 100%;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    transform: translateX(-50%);
  }
}

.progress-bar-circle {
  display: inline-block;
  position: relative;
  text-align: center;
  line-height: 1.2;

  canvas {
    vertical-align: middle;
  }

  span {
    position: absolute;
    top: 48%;
    left: 50%;
    margin-top: -5px;
    font-weight: 400;
    font-size: 60px;
    line-height: 60px;
    font-family: $font-family-serif;
    color: $gray;
    transform: translate(-50%, -50%);
    .context-dark & {
      color: $white;
    }
  }
}
.pricing-plan {
    border-bottom: 1px solid #e1f1ff;
    padding: 25px;
    box-shadow: rgb(0 0 0 / 6%) 0px 2px 4px 0px inset;
    
    .pricing-header {
        color: #888;
        font-weight: 600;
        letter-spacing: 1px;
        font-size: 20px;
        text-transform: uppercase;
    }
    .pricing-features {
        color: #585858;
        font-weight: 600;
        letter-spacing: 1px;
        margin: 50px 0 25px;
        .pricing-features-item {
            border-top: 1px solid #e1f1ff;
            font-size: 12px;
            line-height: 1.5;
            padding: 15px 0;
            &:last-child {
                border-bottom: 1px solid #e1f1ff;
            }
        }
    }
    .pricing-price {
        color: #585858;
        display: block;
        font-size: 20px;
        font-weight: 700;
    }
}

@media (min-width: 900px){
    .pricing-plan {
        border-bottom: none;
        flex-basis: 100%;
    }
}
// Ends: Price Plan ends
//
// Tables Custom
// --------------------------------------------------


.table-custom-xs {
  > tbody > tr {
    > th, > td {
      padding: 10px 15px;

      @include media-breakpoint-up(md) {
        padding: 15px 20px;
        width: 50%;
      }
    }
  }
}

.table-custom-sm {
  > tbody > tr {
    > th, > td {
      padding: 10px 15px;

      @include media-breakpoint-up(md) {
        padding: 16px 30px;

        &:first-child {
          width: 60%;
        }
      }
    }
  }
}

.table-custom-md {
  > tbody > tr {
    > th, > td {
      padding: 15px 22px;
    }

    @include media-breakpoint-up(md) {
      &:first-child {
        > th, > td {
          &:first-child {
            width: 52%;
          }
          &:nth-child(2) {
            width: 17%;
          }
          &:nth-child(3) {
            width: 19%;
          }
        }
      }
    }
  }
}


// Table Bordered
// --------------------------------------------------

.table-bordered {
  > tbody > tr {
    > th, > td {
      border-color: #f5f5f5;
    }
  }
}

.table-bordered-custom {
  > tbody > tr {
    > th, > td {
      border-color: #f5f5f5;

      &:first-child {
        border-left: 1px solid #f5f5f5;
      }
      &:last-child {
        border-right: 1px solid #f5f5f5;
      }
    }

    &:last-child {
      > th, > td {
        border-bottom: 1px solid #f5f5f5;
      }
    }
  }
}


// Table Primary
// --------------------------------------------------

.table-primary {
  > tbody > tr {
    &:first-child {
      > th, > td {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }
}
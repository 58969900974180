//
// RD Flickr Feed
// --------------------------------------------------

.widget-flickrfeed {
  .flickr-item {
    max-width: 85px;

    img {
      transition: .3s all ease;
      will-change: contents;
    }

    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
}

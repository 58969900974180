.gallery-item {
	position: relative;
	overflow: hidden;
	background: $gray-base;
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
	.caption {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		padding: 15px;
		color: $white;
		[data-x-mode="true"] & {
			pointer-events: none;
		}

		& > * {
			[data-x-mode="true"] & {
				pointer-events: auto;
			}
		}

		* { color: $white; }
	}

	.heading,
	.exeption { max-width: 100%; }

	.heading { font-weight: 700; }
	.heading a:hover { color: $gray-light; }
	.exeption { font-size: 14px; }
	.divider-1:before { border-top: 4px solid; }

	img {
		width: 100%;
		opacity: .5;
	}

	.zoom-item {
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 2;
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		width: 60px;
		height: 60px;
		pointer-events: none;
		cursor: pointer;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 160%;
			pointer-events: auto;
			background: $primary;
			transform: rotate(45deg);
			transform-origin: left bottom;
		}

		&:after {
			content: "\e8ff";
			position: relative;
			z-index: 1;
			font-family: "Material Icons";
			font-size: 30px;
			line-height: 1;
			color: $white;
			padding: 2px;
		}
	}

	* + .divider { margin-top: 10px; }
	* + .exeption { margin-top: 10px; }

	&.item-dark {
		img { opacity: .2; }
	}

	@media (min-width: $screen-lg) {
		.caption { padding: 30px 14%; }

		.zoom-item {
			width: 80px;
			height: 100px;

			&:after {
				padding: 4px;
				font-size: 40px;
			}
		}
	}

	@media (min-width: $screen-xl) {
		.exeption {
			font-size: $font-size-base;
		}

		.zoom-item {
			width: 100px;
			height: 100px;

			&:after {
				padding: 9px;
				font-size: 40px;
			}
		}

		* + .divider { margin-top: 31px; }
	}
}

.gallery-item-inner {
	display: block;
}

.desktop .gallery-item {
	.caption {
		opacity: 0;
		transition: .5s .2s;
	}

	img {
		transform: scale(1, 1);
		opacity: 1;
		transition: 1s;
		will-change: transform;
	}

	.zoom-item {
		transform: translateY(100%);
		transition: .3s .2s;
	}

	&:hover {
		img {
			transform: scale(1.05, 1.05);
			opacity: 0.5;
		}

		.caption { opacity: 1; }
		.zoom-item { transform: translateY(0); }
	}

	&.item-dark {
		img { opacity: 1; }

		&:hover {
			img { opacity: .2; }
		}
	}
}

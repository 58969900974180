//
// Nav custom
// --------------------------------------------------

// Tabs
//--------------------------------------
// Corporate style
$tabs-corporate-color: $primary;
$tabs-corporate-background: transparent;
$tabs-corporate-active-color: $white;
$tabs-corporate-active-background: $primary;
$tabs-corporate-border: $brand-gallery;
$tabs-corporate-arrow-color: $primary;

// Line style
$tabs-line-color: $primary;
$tabs-line-background: transparent;
$tabs-line-active-color: $primary;
$tabs-line-active-background: transparent;
$tabs-line-active-border: $primary;
$tabs-line-line: $brand-gallery;

// Minimal style
$tabs-minimal-color: $black;
$tabs-minimal-background: transparent;
$tabs-minimal-active-color: $primary;
$tabs-minimal-active-background: transparent;
$tabs-minimal-active-border: $primary;

$vertical-tab-offset: 10px;

// Base styles
.tabs-custom {
	.nav-link {
		word-spacing: 0;
		border: 0;

		&:before,
		&:after {
			display: none;
		}

		li {
			float: none;
			border: 0;
			cursor: pointer;
			transition: .33s all ease;
		}

		li a.active {
			cursor: default;
			border: 0;
		}

		a {
			display: block;
			margin: 0;
			border: 0;
		}
	}
}

// Offsets
* + .tabs-custom {
	margin-top: 35px;

	@include media-breakpoint-up(lg) {
		margin-top: 50px;
	}
}

.tabs-custom {
	&.tabs-corporate,
	&.tabs-line,
	&.tabs-minimal {
		.nav-link {
			font-size: 0;
			line-height: 0;
		}
	}

	// Tabs corporate style
	&.tabs-corporate {
		.nav-link {
			li {
				margin: -1px 0;

				& + li {
					margin-top: 7px;

					@include media-breakpoint-up(md) {
						margin-top: 0;
						margin-left: 7px;
					}
				}
			}

			li a {
				padding: 8px 10px;
				font: 700 14px/14px $font-family-base;
				border-radius: 50px;
				color: $tabs-corporate-color;
				background: $tabs-corporate-background;
				border: 2px solid $brand-gallery;
				text-align: center;
				vertical-align: middle;
				transition: .3s all ease;
			}

			li a:hover,
			li a.active {
				color: $tabs-corporate-active-color;
				background: $tabs-corporate-active-background;
				border-color: $tabs-corporate-active-background;

				&:after {
					top: -1px;
					color: $tabs-corporate-arrow-color;
				}
			}
		}

		.tab-content {
			padding: 22px 0 0;
		}
	}

	// Tabs line style & Tabs minimal style
	&.tabs-line,
	&.tabs-minimal {
		.nav-link {
			li {
				margin: 0;
			}

			li + li {
				margin-top: -1px;
			}
		}

		.tab-content {
			padding: 22px 0 0;
		}
	}

	// Tabs line style
	&.tabs-line {
		.nav-link {
			li a {
				font: 400 11px/16px $font-family-sec;
				letter-spacing: -.05em;
				text-transform: uppercase;
				color: $tabs-line-color;
			}

			li a:hover,
			li a.active {
				color: $tabs-line-active-color;
			}
		}
	}

	// Tabs minimal style
	&.tabs-minimal {
		.nav-link {
			li a {
				font: 700 11px/24px $font-family-sec;
				color: $tabs-minimal-color;
			}

			li a:hover,
			li a.active {
				color: $tabs-minimal-active-color;
			}
		}
	}
}

// Horizontal tabs
// ----------------------
.tabs-custom {
	// Tabs line style
	&.tabs-line {
		.nav-link {
			text-align: center;

			li {
				display: block;
				margin: 0 -1px;
			}

			li a {
				padding: 8px 15px;
				border: 1px solid $tabs-line-line;

				&:last-child {
					margin-right: 0;
					border-bottom-width: 1px;
				}
			}

			li a:hover,
			li a.active {
				color: $white;
				background: $primary;
				border-color: $primary;
			}
		}
	}

	// Tabs minimal style
	&.tabs-minimal {
		.nav-link {
			text-align: center;

			li {
				display: block;
			}

			li a {
				padding: 7px 15px;
				border: 1px solid $tabs-line-line;

				&:last-child {
					border-bottom-width: 1px;
				}
			}

			li a:hover,
			li a.active {
				color: $white;
				background: $primary;
				border-color: $primary;
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.tabs-custom {

		// Tabs line style & Tabs minimal style
		&.tabs-line,
		&.tabs-minimal {
			li {
				margin: 0;
			}
		}

		&.tabs-line {
			.nav-link {
				li a {
					font-size: 14px;
					line-height: 24px;
				}
			}
		}

		&.tabs-minimal {
			.nav-link {
				li a {
					font-size: 14px;
					line-height: 24px;
				}
			}
		}

		// Horizontal tabs
		// ----------------------
		&.tabs-horizontal {
			// Tabs corporate style & Tabs line style
			&.tabs-corporate,
			&.tabs-line {
				.nav-link {
					display: flex;
					flex-direction: row;
					align-items: center;
					flex-wrap: nowrap;
					width: 100%;
					text-align: left;
				}
			}

			// Tabs line style & Tabs minimal style
			&.tabs-line,
			&.tabs-minimal {
				.nav-link {
					li a {
						position: relative;
						z-index: 10;
						display: inline-block;
						border: 0;
					}

					li + li {
						margin-top: 0;
					}
				}

				.tab-content {
					padding: 40px 0 0;
				}
			}

			// Tabs corporate style
			&.tabs-corporate {
				.nav-link {
					li, a {
						display: block;
					}
				}
			}

			// Tabs line style
			&.tabs-line {
				.nav-link {
					justify-content: space-between;
					border-bottom: 2px solid $tabs-line-line;

					li a {
						padding: 8px 0 8px 0;
						margin: 0 30px 0 0;
						font-weight: 700;
						background: $tabs-line-background;

						&:after {
							content: '';
							position: absolute;
							left: 0;
							right: 100%;
							bottom: -1px;
							border-bottom: 2px solid $tabs-line-active-border;
							opacity: 0;
							visibility: hidden;
							transition: .33s all ease;
						}
					}

					li a:hover,
					li a.active {
						color: $tabs-line-active-color;
						background: $tabs-line-active-background;
					}

					li a.active {
						&:after {
							right: 0;
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}

			// Tabs minimal style
			&.tabs-minimal {
				.nav-link {
					margin-top: -$vertical-tab-offset;

					li {
						display: inline-block;
						margin: $vertical-tab-offset 35px 0 0;
					}

					li a {
						position: relative;
						bottom: -1px;
						z-index: 10;
						display: inline-block;
						padding: 0 0 5px 0;

						&:after {
							content: '';
							position: absolute;
							left: 0;
							right: 100%;
							bottom: 0;
							border-bottom: 2px solid $tabs-line-active-border;
							opacity: 0;
							visibility: hidden;
							transition: .33s all ease;
						}

						&:last-child {
							margin-right: 0;
						}
					}

					li a:hover,
					li a.active {
						color: $tabs-minimal-active-color;
						background: $tabs-minimal-active-background;
					}

					li a.active {
						&:after {
							right: 0;
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}
		}
	}
}

// Vertical tabs
// ----------------------
.tabs-vertical {

	// Common styles
	.nav-link {
		position: relative;

		> li {
			z-index: 10;
			display: block;
			flex-grow: 1;
		}
	}

	// Tabs corporate style
	&.tabs-corporate {
		.nav-link {
			width: 100%;

			li {
				display: block;
			}

			li a {
				position: relative;
				padding: 8px 10px;
			}

			li a:hover,
			li a.active {
				border-color: $tabs-corporate-active-background;
			}
		}

		.tab-content {
			padding: 30px 0 0;
		}
	}

	// Tabs minimal style
	&.tabs-minimal {
		.nav-link {
			border-right: 1px solid $brand-gallery;
			li a {
				position: relative;
				right: -1px;
				padding: 0 16px 0 0;
				text-align: right;
				border-right: 1px solid transparent;
				background-color: transparent;

				&:hover,
				&.resp-tab-active {
					border-right-color: $brand-gallery;
				}
			}

			li + li {
				margin-top: 16px;
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.tabs-custom {
		// Horizontal tabs
		// ----------------------
		&.tabs-horizontal {

			// Tabs corporate style
			&.tabs-corporate {
				.nav-link {
					position: relative;
					justify-content: center;

					li {
						flex-grow: 1;
						flex-shrink: 0;
					}

					li a {
						display: block;
						padding: 17px 10px;
						min-width: 135px;
					}
				}

				.tab-content {
					padding: 30px 0 0;
				}
			}
		}

		// Vertical tabs
		// ----------------------
		&.tabs-vertical {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: flex-start;

			.nav-link {
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				align-items: stretch;
				flex-shrink: 0;
				max-width: 50%;

				li {
					width: 100%;
				}

				li a {
					text-align: left;
				}

				li a:hover,
				li a.active {
					border-color: $tabs-corporate-active-background;

					&:after {
						right: 15px;
					}
				}
			}

			.tab-content {
				flex-grow: 1;
			}

			&.tabs-corporate {
				.nav-link {
					width: auto;
					min-width: 240px;
					border-width: 0 0 1px 0;

					li {
						margin: 0;

						& + li {
							margin-top: 8px;
						}
					}

					li a {
						padding: 15px 44px 18px 31px;

						text-align: left;
					}

					li a:hover,
					li a.active {
						&:after {
							right: 15px;
							top: 50%;
						}
					}
				}

				.tab-content {
					padding: 0 0 0 33px;
				}
			}
		}

		&.tabs-vertical-xs.tabs-custom {
			.nav-link li a {
				padding: 15px 24px 18px 23px;
			}
		}
	}
}

//@media (max-width: $screen-md-min - 1) {
//	&.tabs-vertical-variant-2.tabs-vertical-xs.tabs-custom {
//		display: block;
//
//		.nav-link {
//			display: block;
//			min-width: 0;
//			max-width: none;
//
//			li a {
//				padding: 8px 10px;
//				text-align: center;
//			}
//		}
//
//		.tab-content {
//			padding-top: 30px;
//			padding-left: 0;
//		}
//	}
//}

@include media-breakpoint-up(lg) {
	.tabs-custom {

		// Vertical tabs
		// ----------------------
		&.tabs-vertical {

			// Tabs corporate style
			&.tabs-corporate {
				.nav-link {
					min-width: 170px;
				}
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.tab-content-title-offset {
		.tab-content-title {
			margin-top: -75px;
		}
	}
}